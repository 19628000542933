<div class="modal">
  <div class="header">
    <h2>Leaderboard</h2>
    <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
  </div>

  <div class="modal-content">
    <h3>How it works?</h3>

    <h4>
      Participate in Fan Area activities on The Colosseum and earn leaderboard points. Fans at the top of the
      leaderboard will receive exclusive GG rewards!
    </h4>

    <h4>Points Collection</h4>

    <p class="title">Quizzes</p>
    <ul>
      <li>3 points if the correct answer is selected</li>
      <li>1 point per quiz for participation</li>
    </ul>

    <p class="title">Voting polls (only for Silver and above members)</p>
    <ul>
      <li>10 points per poll for participation</li>
    </ul>

    <p class="title">Referrals</p>
    <ul>
      <li>5 points for new Silver member</li>
      <li>15 points for new Gold member</li>
      <li>50 points for new Platinum member</li>
    </ul>
  </div>
</div>
