import { Component } from '@angular/core';

@Component({
  selector: 'nft-market-page',
  templateUrl: './nft-market-page.component.html',
  styleUrls: ['./nft-market-page.component.scss']
})
export class NftMarketPageComponent {

}
