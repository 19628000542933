import { CdkOverlayOrigin, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { sideMenu, SideMenu } from './main-menu-items';
import { ComponentPortal } from '@angular/cdk/portal';
import { MenuDropdownComponent } from './menu-dropdown/menu-dropdown.component';
import { BehaviorSubject, filter, map, take } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MenuBottomSheetComponent } from './menu-bottom-sheet/menu-bottom-sheet.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Injectable()
export class MainMenuService {
  private sideMenuSrc = new BehaviorSubject<SideMenu[]>(sideMenu);
  public sideMenu$ = this.sideMenuSrc.asObservable();

  public mobileMenu$ = this.sideMenu$.pipe(map((items) => items.filter((item) => item.bottomMenu)));

  private isSideBarOpenSrc = new BehaviorSubject<boolean>(false);
  public isSideBarOpen$ = this.isSideBarOpenSrc.asObservable();

  overlayRef?: OverlayRef;

  constructor(
    private overlay: Overlay,
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
    this.breakpointObserver.observe(['(min-width: 769px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.bottomSheet.dismiss();
      }
    });

    this.router.events.pipe(filter(() => !!this.bottomSheet._openedBottomSheetRef)).subscribe(() => {
      this.bottomSheet.dismiss();
    });
  }

  openSidebar() {
    this.isSideBarOpenSrc.next(true);
  }

  closeSidebar() {
    this.isSideBarOpenSrc.next(false);
  }

  openDropdown(item: SideMenu, trigger: CdkOverlayOrigin) {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    } else {
      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(trigger.elementRef)
        .withPositions([
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top'
          }
        ])
        .withDefaultOffsetX(8);

      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        positionStrategy
      });

      const portal = new ComponentPortal(MenuDropdownComponent);
      const menuRef = this.overlayRef.attach(portal);

      const menuInstance = menuRef.instance;
      menuInstance.items = item.dropdown!.filter((item) => !item.hideDesktop);

      this.overlayRef
        .backdropClick()
        .pipe(take(1))
        .subscribe(() => this.overlayRef!.detach());
    }
  }

  closeDropdown() {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  toggleBottomSheet(item: SideMenu) {
    this.bottomSheet._openedBottomSheetRef ? this.closeBottomSheet() : this.openBottomSheet(item);
  }

  openBottomSheet(item: SideMenu) {
    this.bottomSheet.open(MenuBottomSheetComponent, {
      panelClass: 'sheet-fan-area-menu',
      backdropClass: 'sheet-fan-area-menu-backdrop',
      closeOnNavigation: true,
      data: {
        items: item.dropdown
      }
    });
  }

  closeBottomSheet() {
    this.bottomSheet.dismiss();
  }
}
