<div class="modal">
  <div class="header">
    <h2>Complete Your Profile Setup</h2>
    <span class="icon-Close close-icon" title="Close" (click)="close()"></span>
  </div>
  <div class="modal-content">
    <p>Please visit the profile page to fill in the necessary information.</p>
    <ol>
      <li>
        Set Up Your Profile Details:
        <ul>
          <li>Username</li>
          <li>Phone Number</li>
          <li>Connect Discord Account</li>
        </ul>
      </li>
      <li>Connect Your Wallet</li>
    </ol>
    <app-button (click)="goToProfile()" [buttonData]="profileBtn"></app-button>
  </div>
</div>
