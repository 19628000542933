import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { UnauthorizedContent } from '../intarfaces';

@Injectable({
  providedIn: 'root'
})
export class AdditionalService {
  previousValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private meta: Meta,
    private titleService: Title
  ) {}

  allowOnlyNumbers(event: any) {
    const allowedRegex = /\d/g;
    if (!allowedRegex.test(event.key)) {
      event.preventDefault();
    }
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [p: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  regexValidatorV2(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = !regex.test(control.value);
      return valid ? null : error;
    };
  }

  normalizeAmount(keyPressed: string, amount: string, countNumbersAfterDot: number) {
    const validValues = '1234567890.';
    const beforeAndAfterDot = amount.split('.');

    if (beforeAndAfterDot.length > 2) {
      return this.previousValue$.getValue();
    }

    if (validValues.includes(keyPressed)) {
      if (beforeAndAfterDot[0].startsWith('0') && beforeAndAfterDot[0].length > 1) {
        return amount.slice(1);
      }

      return !(beforeAndAfterDot.length > 2 || beforeAndAfterDot[1]?.length > countNumbersAfterDot)
        ? amount
        : amount.slice(0, -1);
    }
    return amount.split(keyPressed).join('');
  }

  backClicked() {
    window.history.back();
  }

  updateMetaTags(content: UnauthorizedContent) {
    this.titleService.setTitle(content.title);
    this.meta.updateTag({ name: 'description', content: content.description });
    this.meta.updateTag({ property: 'og:title', content: content.title });
    this.meta.updateTag({ property: 'og:description', content: content.description });
    this.meta.updateTag({ property: 'og:url', content: content.url! });
    this.meta.updateTag({ property: 'og:image', content: content.image });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'twitter:image', content: content.image });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:site', content: '@GaiminGladiators' });
    this.meta.updateTag({ name: 'twitter:creator', content: '@GaiminGladiators' });
  }
}
