import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { WalletConnectService } from 'src/app/shared/services/wallet-connect.service';
import { environment } from 'src/environments/environment';
import { AuthStateService } from 'src/app/shared/services/auth/auth-state.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;

  private readonly DESCRIPTION_EXCLUSION_LIST: string[] = [
    'Username is not unique',
    'Claim already submitted',
    'Event not found'
  ];

  constructor(
    private readonly authService: AuthService,
    private readonly authStateService: AuthStateService,
    private readonly walletConnectService: WalletConnectService,
    private readonly toastrService: ToastrService
  ) {}

  /**
   * Intercepts HTTP requests to add authorization token and handle errors.
   * @param {HttpRequest<unknown>} request - The outgoing HTTP request.
   * @param {HttpHandler} next - The next interceptor in the chain.
   * @returns {Observable<HttpEvent<unknown>>} - An observable of the HTTP event.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body != null && !event.body.success) {
          if (event.body.error?.description != null && event.body.error?.type !== 'AUTHENTICATION') {
            if (!this.DESCRIPTION_EXCLUSION_LIST.includes(event.body.error?.description)) {
              const parseError = event.body.error?.description.split('\n');
              if (parseError[1]) {
                this.toastrService.error(parseError[1], parseError[0]);
                return;
              }
              this.toastrService.error(parseError[0]);
            }
          }
          if (
            event.url?.includes(environment.gaiminApi + '/users/auth/refresh') &&
            event.body.error?.type === 'AUTHORIZATION'
          ) {
            this.logoutProcess();
          }
          if (event.body.error?.type === 'AUTHENTICATION' && !request.url.includes('roles')) {
            this.handle401Error();
          }
        }
      }),
      catchError((error) => {
        return throwError(() => {
          this.toastrService.error(error.message);
        });
      })
    );
  }

  /**
   * Handles 401 errors by attempting to refresh the token.
   */
  private handle401Error() {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      const refreshToken = this.authStateService.refreshTokenSnapshot;

      if (refreshToken) {
        return this.authService
          .performRefreshTokenRequest()
          .pipe(
            tap(() => {
              this.isRefreshing = false;
            }),
            catchError((error) => {
              this.isRefreshing = false;
              this.logoutProcess();
              return throwError(() => error);
            })
          )
          .subscribe();
      } else {
        console.log('Refresh token doesn`t exist');
        this.logoutProcess();
        return EMPTY;
      }
    }
    return EMPTY;
  }

  /**
   * Logs out the user and disconnects from the wallet.
   */
  private logoutProcess() {
    this.walletConnectService.disconnectFromWallet();
    this.authService.logout();
  }
}
