import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { LazyLoadDirective } from 'src/app/shared/directives/lazy-load.directive';
import { TooltipModule } from 'src/app/shared/directives/tooltip-module/tooltip.module';
import { MainMenuComponent } from './main-menu.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MenuDropdownComponent } from './menu-dropdown/menu-dropdown.component';
import { MainMenuService } from './main-menu.service';
import { MenuBottomSheetComponent } from './menu-bottom-sheet/menu-bottom-sheet.component';

@NgModule({
  declarations: [MainMenuComponent, MenuDropdownComponent, MenuBottomSheetComponent],
  exports: [MainMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    ButtonComponent,
    TooltipModule,
    MatBottomSheetModule,
    OverlayModule,
    LazyLoadDirective
  ],
  providers: [MainMenuService]
})
export class MainMenuModule {}
