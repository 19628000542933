<ng-container
  *ngIf="
    (userService.userInfo$ | async)?.colosseumTrial &&
    (userService.userInfo$ | async)?.colosseumTrial?.colosseumTrialStatus !== USER_VERIFICATION_STATUS.VERIFIED
  ">
  <div class="email-confirmation">
    <p *ngIf="(userService.userInfo$ | async)?.colosseumTrial?.colosseumTrialStatus === USER_VERIFICATION_STATUS.TRIAL">
      Verify your email before
      {{ (userService.userInfo$ | async)!.colosseumTrial?.trialExpirationDate | date: 'dd.MM.yyyy' }} to continue to
      access The Colosseum!
    </p>
    <p
      *ngIf="(userService.userInfo$ | async)?.colosseumTrial?.colosseumTrialStatus === USER_VERIFICATION_STATUS.EXPIRED">
      Please verify your email to access The Colosseum!
    </p>
    <span (click)="resendEmailVerification()">Re-send email</span>
  </div>
</ng-container>
