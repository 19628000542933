import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuModule } from './layout/main-menu/main-menu.module';
import { FloatingMenuModule } from './layout/floating-menu/floating-menu.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { ToastrModule } from 'ngx-toastr';

import oktaConfig from '../app.config';
const oktaAuth = new OktaAuth(oktaConfig.oidc);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      closeButton: true,
      timeOut: 5000,
      preventDuplicates: true
    }),
    FloatingMenuModule,
    MainMenuModule
  ],
  exports: [MainMenuModule, FloatingMenuModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {}
