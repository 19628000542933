<div class="fan-area-menu">
  <a *ngFor="let item of items$ | async" class="fan-area-menu__item" [routerLink]="item.path">
    <div class="fan-area-menu__item-icon">
      <span class="icon {{ item.icon }}"></span>

      <div *ngIf="item.badge" class="notification"></div>
    </div>

    <span>{{ item.title }}</span>
  </a>
</div>
