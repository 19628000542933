import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent implements OnInit {
  tooltip: string = '';
  left: string = 'auto';
  right: string = 'auto';
  top: string = 'auto';
  bottom: string = 'auto';
  position: 'top' | 'right' | 'left' | 'bottom' = 'top';
  isMobileTransformLeft: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
