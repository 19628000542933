import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent } from '../../button/button.component';
import { UserService } from '../../../services/user.service';
import { UserInfo, Button } from '../../../intarfaces';
import { BUTTON_SIZE } from '../../../enums';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-update-gaimin-account-modal',
  templateUrl: './update-gaimin-account-modal.component.html',
  styleUrl: './update-gaimin-account-modal.component.scss',
  imports: [CommonModule, ButtonComponent],
  standalone: true
})
export class UpdateGaiminAccountModalComponent implements OnInit {
  confirmButton: Button = { name: 'Sign Up Again Now', size: BUTTON_SIZE.MD };
  profile: UserInfo = {} as UserInfo;
  date: string = 'November 18, 2024';

  constructor(
    public userService: UserService,
    private dialogRef: MatDialogRef<UpdateGaiminAccountModalComponent>
  ) {
    this.profile = this.userService.userInfo$.getValue();
  }

  ngOnInit(): void {}

  redirectToAuthModule() {
    const returnURL = btoa(encodeURIComponent(window.location.origin + '/content?type=migrate'));
    console.log(returnURL);
    const userData = btoa(
      JSON.stringify({
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        email: this.profile.email,
        username: this.profile.username,
        imageUrl: this.profile.avatarUrl
      })
    );
    const redirectURL = `${environment.authModuleUrl}/signup?returnURL=${returnURL}&user=${userData}`;
    window.location.href = redirectURL;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
