<div class="home">
  <div class="home__hero">
    <div class="home__bg">
      <picture>
        <source srcset="assets/icons/home/bg.webp" type="image/webp" />
        <source srcset="assets/icons/home/bg.png" type="image/png" />
        <img  ngSrc="assets/icons/home/bg.png" priority fill alt="background" />
      </picture>
    </div>


    <app-home-header></app-home-header> 

    <div class="home__banner">
      <div class="home__banner-content">
        <div class="home__banner-games" @fadeInOut *ngIf="loaded">
          <img class="dota" ngSrc="assets/icons/home/banner-dota.svg" width="45" height="46" alt="Dota" priority />
          <img
            class="battlegrounds"
            ngSrc="assets/icons/home/banner-battlegrounds.svg"
            width="124"
            height="38"
            alt="Battlegrounds"
            priority />
          <img
            class="fortnite"
            ngSrc="assets/icons/home/banner-fortnite.svg"
            alt="Fortnite"
            width="102"
            height="30"
            priority />
          <img
            class="tekken"
            ngSrc="assets/icons/home/banner-tekken.svg"
            alt="Tekken"
            width="176"
            height="24"
            priority />
          <img
            class="street-fighter"
            ngSrc="assets/icons/home/banner-street-fighter.svg"
            width="92"
            height="46"
            alt="Street Fighter"
            priority />
          <img
            class="rocket-league"
            ngSrc="assets/icons/home/banner-rocket-league.svg"
            width="94"
            height="34"
            alt="Rocket League"
            priority />
          <img class="apex" ngSrc="assets/icons/home/banner-apex.svg" width="75" height="46" alt="Apex" priority />
          <img
            class="star-craft"
            ngSrc="assets/icons/home/banner-star-craft.svg"
            width="131"
            height="46"
            alt="Star Craft"
            priority />
          <img
            class="mobile-legends"
            ngSrc="assets/icons/home/banner-mobile-legends.svg"
            width="129"
            height="40"
            alt="Mobile Legends"
            priority />
        </div>
        <h1>Join The Gaimin Gladiators "Colosseum"</h1>
        <h2>Unlock VIP benefits via our exclusive Gaimin Gladiators Fan Token «GMRX»</h2>
      </div>
      <picture>
        <source srcset="assets/icons/home/banner.webp" type="image/webp" />
        <source srcset="assets/icons/home/banner.png" type="image/png" />
        <img
          class="home__banner-img"
          ngSrc="assets/icons/home/banner.png"
          priority
          width="984"
          height="449"
          alt="The Colosseum" />
      </picture>
    </div>
    <div class="home__info">
      <app-button class="member-btn" (click)="goToPackages()" [buttonData]="memberBtn"></app-button>
      <h3>Join the inner circle of the most loyal esports fans worldwide!</h3>
    </div>
  </div>

  <div class="home-video">
    <app-youtube></app-youtube>
  </div>

  <div class="home-wide-container" *ngIf="loaded" @fadeInOut>
    <div id="packagesSection" class="home__packages">
      <h2 class="title">Choose your package</h2>
      <app-packages [isLevelsVisible]="false"></app-packages>
      <div class="home__packages-link">
        For more information about our packages, click
        <a href="/packages">here</a>
      </div>
    </div>

    <div id="faqsSection" class="home__faqs">
      <h2>The Colosseum FAQs</h2>
      <h3>
        For more information about The Colosseum, contact us at
        <a href="mailto:colosseum@gaimin.io">colosseum&#64;gaimin.io</a>
      </h3>
      <app-faqs-guides></app-faqs-guides>
      <div class="home__faqs-link">
        Looking for more information? <a routerLink="/faqs">Click here</a> to visit our FAQs page.
      </div>
    </div>
  </div>
  <app-ticker *ngIf="loaded" @fadeInOut></app-ticker>
  <app-home-footer></app-home-footer>
</div>
