<div class="confirm-subscription">
  <div class="header">
    <h2>Are you sure you want to {{ headerMove.toLowerCase() }} your subscription?</h2>
    <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
  </div>
  <div class="confirm-subscription__info">
    <h2>{{ tierName }}</h2>
    <img
      class="confirm-subscription__info-img"
      [src]="'assets/icons/packages/' + tierName.toLowerCase() + '.png'"
      [alt]="tierName"
      lazyLoad />
    <p class="confirm-subscription__info-text">Tokens will remain locked for {{ susbsService.unlockedDays }} days</p>
    <app-button (click)="confirm()" [buttonData]="confirmBtn"></app-button>
  </div>
</div>
