import { Component, Input } from '@angular/core';
import { Button } from '../../interfaces';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class ButtonComponent {
  @Input() buttonData!: Button;
  @Input() disabled: boolean = false;
  @Input() buttonType: 'submit' | 'button' = 'submit';

  constructor() {}
}
