import { Component } from '@angular/core';
import { Infographics } from '../../intarfaces';
import { InfographicsComponent } from '../infographics/infographics.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { LazyLoadDirective } from '../../directives/lazy-load.directive';

@Component({
  selector: 'app-faqs-guides',
  templateUrl: './faqs-guides.component.html',
  styleUrls: ['./faqs-guides.component.scss'],
  imports: [CommonModule ,InfographicsComponent, MatExpansionModule, LazyLoadDirective],
  standalone: true
})
export class FaqsGuidesComponent {
  imagePath = 'assets/icons/infographics/guides/';
  panelOpenState: string = '';

  infographics: Infographics = {
    item1: { folder: this.imagePath + 'how_to_become_a_member/', count: 4 },
    item2: { folder: this.imagePath + 'how_to_create_a_wallet/', count: 4 },
    item3: { folder: this.imagePath + 'how_to_setup_profile/', count: 4 },
    item6: { folder: this.imagePath + 'how_to_connect_a_wallet/', count: 4 },
    item7: { folder: this.imagePath + 'how_to_donate/', count: 3 },
    item8: { folder: this.imagePath + 'how_to_earn/', count: 3 }
  };

  scrollToExpanded(id: string) {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }

  setPanelOpenState(item: string) {
    this.panelOpenState = item;
  }
}
