<div class="modal">
  <div class="header">
    <h2>Update Your Gaimin Account by {{ date }}</h2>
    <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
  </div>

  <div class="modal-content">
    <h3>PLEASE READ - VERY IMPORTANT ACCOUNT UPGRADE</h3>
    <p>
      We're improving the security of GAIMIN accounts and introducing an integrated smart wallet feature, giving you
      streamlined access to all GAIMIN products and services with a single sign-on.
    </p>

    <h4>What You Need to Know:</h4>
    <ul>
      <li>
        <p>
          Automatic Migration: Register by {{ date }} to have your current account automatically migrated to the new SSO
          system.
          <i
            ><strong>IMPORTANT</strong> Please sign up for the new SSO (the second time) using your current email on our
            service. If you use a different email address, your account will not be linked to your previous data, which
            may cause issues accessing your GAIMIN services.</i
          >
        </p>
      </li>
      <li>
        <p>
          Manual Process After Deadline: If you miss the deadline, migration will require manual steps, potentially
          delaying access to full features.
        </p>
      </li>
      <li>
        <p>
          Secure Your Recovery Password: During setup, you’ll create a recovery password for your wallet. GAIMIN cannot
          recover this password if lost - please store it safely. You’ll be responsible for managing your wallet
          security going forward.
        </p>
      </li>
    </ul>
    <p>
      Why This Matters: This upgrade ensures enhanced security and future access to new GAIMIN services. Completing the
      process by {{ date }} and following the instructions above will allow you to take full advantage of these
      improvements without disruption.
    </p>
    <p>
      For any questions, contact our support team at:
      <a href="mailto:technical_support@gaimin.io">technical_support&#64;gaimin.io</a>
    </p>

    <app-button (click)="redirectToAuthModule()" [buttonData]="confirmButton"></app-button>
  </div>
</div>
