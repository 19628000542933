import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  scrollSubject$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor() {}

  scrollTo(section: string) {
    this.scrollSubject$.next(section);
    console.log(this.scrollSubject$.getValue());
  }
}
