import { Component } from '@angular/core';

@Component({
  selector: 'charity-page',
  templateUrl: './charity-page.component.html',
  styleUrls: ['./charity-page.component.scss']
})
export class CharityPageComponent {

}
