import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Button } from '../../../intarfaces';
import { BUTTON_SIZE } from '../../../enums';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../../button/button.component';

@Component({
  selector: 'onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
  imports: [CommonModule, ButtonComponent],
  standalone: true
})
export class OnboardingModalComponent {
  readonly profileBtn: Button = { name: 'Go to my GG Fan Profile', size: BUTTON_SIZE.LG };

  constructor(
    private dialogRef: MatDialogRef<OnboardingModalComponent>,
    private router: Router
  ) {}

  goToProfile() {
    this.close();
    this.router.navigateByUrl('profile');
  }

  close() {
    this.dialogRef.close();
  }
}
