<mat-dialog-actions class="header">
  <h4 class="title">Leaderboard</h4>

  <button class="close-icon" (click)="close()">
    <span class="icon-Close"></span>
  </button>
</mat-dialog-actions>

<mat-dialog-content class="body">
  <div class="leaderboard">
    <h5 class="leaderboard__title">How it works?</h5>

    <ul class="leaderboard__list">
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p>
          <span class="semi">Bronze</span> members can participate in all features except voting polls. These fans also
          only have 3 match predictions per tournament.
        </p>
      </li>
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p>Only silver members and above are eligible for prize pool rewards.</p>
      </li>
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p class="semi">Prize pool: Points reset at the end of the month.</p>
      </li>
    </ul>

    <p class="text">
      Monthly leaderboard point milestones <br />
      Monthly prize pool portion of rewards <br />
      Major tournament rewards per match prediction
    </p>

    <ul class="leaderboard__list">
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p>
          <span class="semi">Referral:</span> Fans share referral link, once new subscriber is achieved then the fan
          receives leadership points.
        </p>
      </li>
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p class="semi">
          The prize pool depends on the number of subscribed members. The more subscribed members the higher the prize
          pool.
        </p>
      </li>
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p class="semi">
          All fans can collect points, however only subscribed members are eligible for their potion of GMRX monthly
          rewards.
        </p>
      </li>
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p class="semi">
          The leaderboard points reset at the end of each month to create equal playing fields to win rewards and
          optimize engagement
        </p>
      </li>
    </ul>

    <p class="title">Token manual claims</p>

    <ul class="leaderboard__list">
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p class="semi">
          As fans collect a certain amount of points they can manually claim GMRX. Then at the end of the month fans can
          manually claim their portion of the total prize pool
        </p>
      </li>
    </ul>
    <p class="text">
      Portion of Prize pool. Min amount needed for fan to qualify for prize pool. (pre-set at the beginning of the
      month)
    </p>

    <p class="title">Points Collection</p>

    <ul class="leaderboard__list">
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p class="semi">Voting polls</p>
      </li>
    </ul>
    <p class="text">10 points per poll for participation</p>

    <ul class="leaderboard__list">
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p class="semi">Player of the month votes</p>
      </li>
    </ul>
    <p class="text">3 points per player vote</p>

    <ul class="leaderboard__list">
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p class="semi">Quizzes</p>
      </li>
    </ul>
    <p class="text">1 point per quiz for participation</p>
    <p class="text">3 points points if the correct answer is selected</p>

    <ul class="leaderboard__list">
      <li class="leaderboard__list__item">
        <div class="dot"></div>
        <p class="semi">New referrals</p>
      </li>
    </ul>
    <p class="text">1 point for 5 new Bronze members</p>
    <p class="text">5 points for new Silver member</p>
    <p class="text">15 points for new Gold member</p>
    <p class="text" style="margin: 0">50 points for new Platinum member</p>
  </div>
</mat-dialog-content>
