import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { EmailConfirmationModalComponent } from '../modals/email-confirmation-modal/email-confirmation-modal.component';
import { AdditionalService } from '../../services/additional.service';
import { UserService } from '../../services/user.service';
import { USER_VERIFICATION_STATUS } from '../../enums';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class EmailConfirmationComponent implements OnInit {
  @AutoUnsubscribe()
  private userSub: Subscription = {} as Subscription;

  get USER_VERIFICATION_STATUS(): typeof USER_VERIFICATION_STATUS {
    return USER_VERIFICATION_STATUS;
  }

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private dialogService: MatDialog,
    public userService: UserService,
    public additionalService: AdditionalService
  ) {}

  ngOnInit() {
    this.userSub = this.userService.userInfo$.subscribe({
      next: (data) => {
        if (data.colosseumTrial?.colosseumTrialStatus == USER_VERIFICATION_STATUS.EXPIRED) {
          if (window.location.pathname !== '/') {
            this.router.navigateByUrl('/');
          } else {
            this.openPopup();
          }
        }
      }
    });
  }

  resendEmailVerification() {
    this.userService.resendConfirmEmailRequest(this.userService.userInfo$.getValue().email).subscribe({
      next: (response) => {
        if (!!response.length) {
          this.toastrService.success(
            "Verification email sent! Please check your inbox. If you don't see it, check your spam."
          );
        }
      }
    });
  }

  openPopup() {
    const dialogRef = this.dialogService.open(EmailConfirmationModalComponent, {
      panelClass: 'dialog-overlay-pane',
      disableClose: true
    });
    dialogRef.componentRef?.instance.resendRequest.subscribe(() => {
      this.resendEmailVerification();
    });
  }
}
