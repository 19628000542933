import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { CHAINS } from '../enums';
import { UserWalletData, Response } from '../intarfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WalletRequestsService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  storeActiveUserWalletRequest(walletAddress: string) {
    const chain = environment.production ? CHAINS.BNB_CHAIN : CHAINS.BNB_CHAIN_TESTNET;
    this.http
      .put(
        `${environment.gaiminApi}/users/me/wallet/active`,
        { wallet: walletAddress, chain }
      )
      .subscribe();
  }

  checkDpaEventEnrollment() {
    this.authService.checkDpaEventEnrollment(false, true);
  }

  getActiveWalletRequest(): Observable<Response<UserWalletData>> {
    return this.http.get<Response<UserWalletData>>(
      `${environment.gaiminApi}/users/me/wallet/active?chain=${CHAINS.BNB_CHAIN}`
    );
  }
}
