import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { PAYMENT_STEPS } from 'src/app/shared/enums';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'subscription-payment-modal',
  templateUrl: './subscription-payment-modal.component.html',
  styleUrls: ['./subscription-payment-modal.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class SubscriptionPaymentModalComponent implements OnInit {
  @AutoUnsubscribe()
  private currentPaymentStepSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private isMobileSub: Subscription = {} as Subscription;

  PAYMENT_STEPS = PAYMENT_STEPS
  steps = [
    {
      step: PAYMENT_STEPS.ALLOW_GMRX_SPEND,
      title: 'Allow GMRX spend',
      activeSteps: [PAYMENT_STEPS.ALLOW_GMRX_SPEND],
    },
    {
      step: PAYMENT_STEPS.CONFIRMAITON_SPEND,
      title: 'Confirmation',
      activeSteps: [PAYMENT_STEPS.CONFIRMAITON_SPEND, PAYMENT_STEPS.SPEND_CONFIRMED],
    },
    {
      step: PAYMENT_STEPS.SWAP_TOKEN,
      title: 'Subscribe',
      activeSteps: [PAYMENT_STEPS.SWAP_TOKEN, PAYMENT_STEPS.CONFIRMAITON_SWAP],
    },
    {
      step: PAYMENT_STEPS.SWAP_TOKEN_CONFIRMED,
      title: 'Confirmation',
      activeSteps: [PAYMENT_STEPS.SWAP_TOKEN_CONFIRMED],
    },
  ]
  currentStepPosition: number = 0;
  transactionUrl = environment.production ? 'https://bscscan.com/tx/' : 'https://testnet.bscscan.com/tx/';
  isMobile = false;

  constructor(private dialogRef: MatDialogRef<SubscriptionPaymentModalComponent>, public subsService: SubscriptionService, private breakpointObserver: BreakpointObserver) {
    this.isMobileSub = this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
  }

  ngOnInit(): void {
    this.currentPaymentStepSub = this.subsService.currentPaymentStep$.subscribe(step => {
      this.currentStepPosition = this.steps.findIndex(value => value.activeSteps.includes(step));
    })
  }

  closeModal() {
    this.dialogRef.close();
    this.subsService.subscriptionPaymentCleanUp();
  }

  isActiveStep(step: PAYMENT_STEPS) {
    return this.steps.find(value => value.activeSteps.includes(step))?.activeSteps.includes(this.subsService.currentPaymentStep$.value);
  }
}
