<div class="leaderboard">
  <div class="leaderboard__profile">
    <div class="header">
      <h3 class="title">Leaderboard</h3>
      <button (click)="openModal()"><span class="icon icon-book-solid-1"></span> <span>How it works</span></button>
    </div>

    <div class="leaderboard__info">
      <img [ngSrc]="'/assets/icons/fan-area/leaderboard/background.png'" alt="background" fill />

      <div class="leaderboard__info__prize">
        <h4>Prize Pool</h4>
        <p>1,000,000 <img src="/assets/icons/fan-area/leaderboard/gmrx.svg" alt="gmrx" /></p>

        <!-- <div class="leaderboard__multiplier">
          <img [ngSrc]="'/assets/icons/fan-area/leaderboard/silver-multiplier.png'" alt="gold multiplier" fill />
          <span>x10</span>
          <img src="/assets/icons/fan-area/leaderboard/money.svg" alt="money" />
          <span>+10 000 members</span>
        </div> -->
      </div>

      <app-button [buttonData]="inviteBtn" (click)="openInviteFriends()"></app-button>

      <img class="gift" src="/assets/icons/fan-area/leaderboard/gift.svg" alt="gift" />
    </div>

    <div class="leaderboard__row profile">
      <div class="leaderboard__row__user cell cell__user">
        <div class="leaderboard__row__rank">
          <ng-container [ngSwitch]="(meLeaderboardData$ | async)?.position">
            <span *ngSwitchCase="0">-</span>
            <img *ngSwitchCase="1" src="/assets/icons/floating-menu/trophy.svg" alt="" />
            <img *ngSwitchCase="2" src="/assets/icons/fan-area/leaderboard/silver-trophy.svg" alt="" />
            <img *ngSwitchCase="3" src="/assets/icons/fan-area/leaderboard/bronze-trophy.svg" alt="" />
            <span *ngSwitchDefault>{{ (meLeaderboardData$ | async)?.position || '-' }}</span>
          </ng-container>
        </div>

        <img
          class="avatar"
          [src]="(meLeaderboardData$ | async)?.avatarUrl"
          alt="avatar"
          onError="this.src = 'assets/icons/default-user-image.png'"
          width="24"
          height="24" />

        <ng-container [ngSwitch]="(meLeaderboardData$ | async)?.tier">
          <img
            *ngSwitchCase="COLOSSEUM_TIERS.BRONZE"
            class="bronze"
            [ngSrc]="'assets/icons/packages/bronze.png'"
            height="24"
            width="22"
            alt="Bronze" />
          <img
            *ngSwitchCase="COLOSSEUM_TIERS.SILVER"
            class="silver"
            [ngSrc]="'assets/icons/packages/silver.png'"
            height="24"
            width="22"
            alt="Silver" />
          <img
            *ngSwitchCase="COLOSSEUM_TIERS.GOLD"
            class="gold"
            [ngSrc]="'assets/icons/packages/gold.png'"
            height="24"
            width="22"
            alt="Gold" />
          <img
            *ngSwitchCase="COLOSSEUM_TIERS.PLATINUM"
            class="platinum"
            [ngSrc]="'assets/icons/packages/platinum.png'"
            height="24"
            width="22"
            alt="platinum" />
        </ng-container>
        <span class="name">
          {{ (meLeaderboardData$ | async)?.username }}
        </span>
      </div>

      <div class="leaderboard__row__points cell cell__points">
        <span>
          {{ (meLeaderboardData$ | async)?.referralPointsBalance | number }}
        </span>
        <!-- <img src="/assets/icons/fan-area/leaderboard/point.svg" alt="point" /> -->
      </div>
    </div>
  </div>

  <div class="leaderboard__table">
    <div class="table-header">
      <div class="cell cell__user">
        <span>#</span>
        <span>Name</span>
      </div>
      <div class="cell cell__prize">Prize <span class="desktop-only">share</span></div>
      <div class="cell cell__points">Points</div>
    </div>

    <ng-container *ngIf="(leaderboardData$ | async)?.referrals?.length">
      <div class="table-body">
        <ng-container *ngIf="isLoading$ | async; else content">
          <div *ngFor="let item of skeletonItems | paginate: (paginationConfig$ | async)!" style="display: none"></div>
          <div style="height: 554px; position: relative">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </ng-container>

        <ng-template #content>
          <div
            *ngFor="let item of (leaderboardData$ | async)!.referrals | paginate: (paginationConfig$ | async)!"
            class="leaderboard__row table"
            [ngClass]="{ me: item.userId === (userInfo$ | async)?.id }">
            <div class="leaderboard__row__user cell cell__user">
              <div class="leaderboard__row__rank">
                <ng-container [ngSwitch]="item.position">
                  <img *ngSwitchCase="1" src="/assets/icons/floating-menu/trophy.svg" alt="" />
                  <img *ngSwitchCase="2" src="/assets/icons/fan-area/leaderboard/silver-trophy.svg" alt="" />
                  <img *ngSwitchCase="3" src="/assets/icons/fan-area/leaderboard/bronze-trophy.svg" alt="" />
                  <span *ngSwitchDefault>{{ item.position }}</span>
                </ng-container>
              </div>

              <img
                class="avatar"
                [src]="item?.avatarUrl || '/assets/icons/default-user-image.png'"
                alt="avatar"
                onError="this.src = 'assets/icons/default-user-image.png'"
                width="24"
                height="24" />

              <ng-container [ngSwitch]="item.tier">
                <img
                  *ngSwitchCase="COLOSSEUM_TIERS.BRONZE"
                  class="bronze"
                  [ngSrc]="'assets/icons/packages/bronze.png'"
                  height="24"
                  width="22"
                  alt="Bronze" />
                <img
                  *ngSwitchCase="COLOSSEUM_TIERS.SILVER"
                  class="silver"
                  [ngSrc]="'assets/icons/packages/silver.png'"
                  height="24"
                  width="22"
                  alt="Silver" />
                <img
                  *ngSwitchCase="COLOSSEUM_TIERS.GOLD"
                  class="gold"
                  [ngSrc]="'assets/icons/packages/gold.png'"
                  height="24"
                  width="22"
                  alt="Gold" />
                <img
                  *ngSwitchCase="COLOSSEUM_TIERS.PLATINUM"
                  class="platinum"
                  [ngSrc]="'assets/icons/packages/platinum.png'"
                  height="24"
                  width="22"
                  alt="platinum" />
              </ng-container>

              <span class="name">
                {{ item.username }}
              </span>
            </div>

            <div class="leaderboard__row__prize cell cell__prize" [ngClass]="{ 'center': item.position > 10 }">
              <ng-container *ngIf="item.position <= 10; else noPrize">
                {{ getPrizeShare(item.position) | number }}
              </ng-container>

              <ng-template #noPrize>
                -
              </ng-template>
            </div>

            <div class="leaderboard__row__points cell cell__points">
              <span>{{ item.referralPointsBalance | number }}</span>
              <!-- <img src="/assets/icons/fan-area/leaderboard/point.svg" alt="point" /> -->
              <!-- <span class="leaderboard__row__points__count">2x</span> -->
            </div>
          </div>
        </ng-template>
      </div>

      <pagination-controls
        [responsive]="false"
        [maxSize]="6"
        [previousLabel]="''"
        [nextLabel]="''"
        (pageChange)="onPageChange($event)"
        id="leaderboard"></pagination-controls>
    </ng-container>
  </div>

  <how-to-earn-points></how-to-earn-points>
</div>
