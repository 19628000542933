import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent } from '../../button/button.component';
import { CommonModule } from '@angular/common';
import { SubscriptionPaymentModalComponent } from '../../modals/subscription-payment-modal/subscription-payment-modal.component';

@Component({
  selector: 'leaderboard-info-modal',
  templateUrl: './leaderboard-info-modal.component.html',
  styleUrls: ['./leaderboard-info-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaderboardInfoModalComponent {
  constructor(
    private dialogRef: MatDialogRef<SubscriptionPaymentModalComponent>
  ) { }

  close() {
    this.dialogRef.close();
  }
}
