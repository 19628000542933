export enum BUTTON_TYPE {
  PRIMARY = 'primary',
  LIGHT = 'light',
  DARK = 'dark',
  DARK_FILLED = 'dark_filled',
  FILLED = 'filled',
  INPUT = 'input',
  ICON = 'icon',
  IMAGE = 'image',
  GREY = 'grey'
}

export enum BUTTON_SIZE {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  AUTO = 'auto'
}

export enum POPOVER_TYPE {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER_RIGHT = 'center-right'
}

export enum COLOSSEUM_TIERS {
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM'
}

export enum COLOSSEUM_PAGES {
  COLOSSEUM_CONTENT = 'COLOSSEUM_CONTENT',
  COLOSSEUM_STORE = 'COLOSSEUM_STORE',
  COLOSSEUM_STREAM = 'COLOSSEUM_STREAM',
  PLATFORM_HOME = 'PLATFORM_HOME'
}

export enum CHAINS {
  BNB_CHAIN = 'BNB_CHAIN',
  BNB_CHAIN_TESTNET = 'BNB_CHAIN_TESTNET',
  POLYGON = 'POLYGON'
}

export enum EXCHANGE_CURRENCY {
  GMRX = 'GMRX',
  BNB = 'BNB'
}

export enum SORTING_ORDER {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum USER_ROLES {
  ROLE_COLOSSEUM_MANAGER = 'ROLE_COLOSSEUM_MANAGER',
  ROLE_USER = 'ROLE_USER'
}

export enum CONTRACT_EVENT_NAMES {
  CHANGED = 'SubscriptionChanged',
  CANCELLED = 'SubscriptionCancelled',
  APPROVAL = 'Approval'
}

export enum USER_VERIFICATION_STATUS {
  TRIAL = 'TRIAL',
  VERIFIED = 'VERIFIED',
  EXPIRED = 'EXPIRED'
}

export enum PAYMENT_STEPS {
  ALLOW_GMRX_SPEND = 'allow_gmrx_spend',
  CONFIRMAITON_SPEND = 'confirmation_spend',
  SPEND_CONFIRMED = 'allow_gmrx_confirmed',
  SWAP_TOKEN = 'swap_token',
  CONFIRMAITON_SWAP = 'confirmation_swap',
  SWAP_TOKEN_CONFIRMED = 'swap_token_confirmed'
}

export enum FAN_AREA_ITEMS {
  NOTIFICATION = 'notification',
  REFERRALS = 'referrals',
  LEADERBOARD = 'leaderboard',
  QUIZ = 'quiz',
  VOTING = 'voting',
  MATCH_PREDICTIONS = 'match-predictions'
}

export enum NOTIFICATION_SUBJECT_TYPE {
  VIDEO = 'VIDEO',
  MOMENT = 'MOMENT',
  ARTICLE = 'ARTICLE',
  STREAMER = 'STREAMER'
}
