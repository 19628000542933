import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  OnDestroy
} from '@angular/core';
import { TooltipComponent } from '../components/tooltip/tooltip.component';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnDestroy {
  @Input() tooltip: string | null = '';
  @Input() tooltipPosition: 'top' | 'right' | 'left' | 'bottom' = 'top';
  @Input() isMobileTransformLeft: boolean = false;
  private componentRef: ComponentRef<any> | null = null;

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  @HostListener('mouseenter')
  @HostListener('click')
  onMouseEnter(): void {
    if (this.componentRef === null && this.tooltip) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
      this.componentRef = componentFactory.create(this.injector);
      this.appRef.attachView(this.componentRef.hostView);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
      this.setTooltipComponentProperties();
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltip;
      this.componentRef.instance.position = this.tooltipPosition;
      this.componentRef.instance.isMobileTransformLeft = this.isMobileTransformLeft;

      const { left, right, top, bottom, width, height } = this.elementRef.nativeElement.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      switch (this.tooltipPosition) {
        case 'top':
          this.componentRef.instance.left = (right - left) / 2 + left + 'px';
          this.componentRef.instance.bottom = windowHeight - bottom + height + 'px';
          break;

        case 'right':
          this.componentRef.instance.left = right + 'px';
          this.componentRef.instance.top = top + height / 2 + 'px';
          break;

        case 'left':
          this.componentRef.instance.right = windowWidth - left + 'px';
          this.componentRef.instance.top = top + height / 2 + 'px';
          break;

        case 'bottom':
          this.componentRef.instance.left = (right - left) / 2 + left + 'px';
          this.componentRef.instance.top = bottom + 5 + 'px';
          break;
      }
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.destroy();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
