import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CryptoWizardDetails, Response } from '../interfaces';
import { environment } from '../../../environments/environment';

@Injectable()
export class MeldService {
  constructor(private http: HttpClient) {}

  getCryptoWizardDetailsRequest(): Observable<Response<CryptoWizardDetails>> {
    return this.http.get<Response<CryptoWizardDetails>>(`${environment.gaiminApi}/meld/session-details`);
  }
}
