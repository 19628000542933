import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, tap } from 'rxjs';
import { USER_ROLES } from '../../enums';
import { OktaAuthStateService } from '@okta/okta-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {
  private accessTokenSrc = new BehaviorSubject<string>(localStorage.getItem('accessToken') || '');
  private refreshTokenSrc = new BehaviorSubject<string>(localStorage.getItem('refreshToken') || '');
  private userRolesSrc = new BehaviorSubject<string>(localStorage.getItem('userRoles') || USER_ROLES.ROLE_USER);
  private managerTokenSrc = new BehaviorSubject<string>(localStorage.getItem('managerToken') || '');
  private utmSourceIdSrc = new BehaviorSubject<string>(localStorage.getItem('utmSourceId') || '');
  private invitingUserIdSrc = new BehaviorSubject<string>(localStorage.getItem('invitingUserId') || '');

  private isLoggedInSrc = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSrc.asObservable();
  get isLoggedIn(): boolean {
    return this.isLoggedInSrc.value;
  }

  constructor(private oktaAuthStateService: OktaAuthStateService) {
    // Subscribe to changes and update localStorage
    this.accessTokenSrc.subscribe((value) => localStorage.setItem('accessToken', value));
    this.refreshTokenSrc.subscribe((value) => localStorage.setItem('refreshToken', value));
    this.userRolesSrc.subscribe((value) => localStorage.setItem('userRoles', value));
    this.managerTokenSrc.subscribe((value) => localStorage.setItem('managerToken', value));
    this.utmSourceIdSrc.subscribe((value) => localStorage.setItem('utmSourceId', value));
    this.invitingUserIdSrc.subscribe((value) => localStorage.setItem('invitingUserId', value));

    combineLatest([this.getRefreshToken$(), this.oktaAuthStateService.authState$])
      .pipe(
        map(([refreshToken, oktaAuthState]) => {
          return !!refreshToken || !!oktaAuthState?.isAuthenticated;
        }),
        tap((isLoggedIn) => this.isLoggedInSrc.next(isLoggedIn))
      )
      .subscribe();
  }

  // Observable getters
  getAccessToken$(): Observable<string> {
    return this.accessTokenSrc.asObservable();
  }

  getRefreshToken$(): Observable<string> {
    return this.refreshTokenSrc.asObservable();
  }

  getUserRoles$(): Observable<string> {
    return this.userRolesSrc.asObservable();
  }

  getManagerToken$(): Observable<string> {
    return this.managerTokenSrc.asObservable();
  }

  getUtmSourceId$(): Observable<string> {
    return this.utmSourceIdSrc.asObservable();
  }

  getInvitingUserId$(): Observable<string> {
    return this.invitingUserIdSrc.asObservable();
  }

  // Synchronous value getters
  get accessTokenSnapshot(): string {
    return this.accessTokenSrc.value;
  }

  get refreshTokenSnapshot(): string {
    return this.refreshTokenSrc.value;
  }

  get userRolesSnapshot(): string {
    return this.userRolesSrc.value;
  }

  get managerTokenSnapshot(): string {
    return this.managerTokenSrc.value;
  }

  get utmSourceIdSnapshot(): string {
    return this.utmSourceIdSrc.value;
  }

  get invitingUserIdSnapshot(): string {
    return this.invitingUserIdSrc.value;
  }

  // Setters
  setAccessToken(token: string): void {
    this.accessTokenSrc.next(token);
  }

  setRefreshToken(token: string): void {
    this.refreshTokenSrc.next(token);
  }

  setUserRoles(roles: string): void {
    this.userRolesSrc.next(roles);
  }

  setManagerToken(token: string): void {
    this.managerTokenSrc.next(token);
  }

  setUtmSourceId(id: string): void {
    this.utmSourceIdSrc.next(id);
  }

  setInvitingUserId(id: string): void {
    this.invitingUserIdSrc.next(id);
  }

  clearData(): void {
    this.accessTokenSrc.next('');
    this.refreshTokenSrc.next('');
    this.userRolesSrc.next(USER_ROLES.ROLE_USER);
    this.managerTokenSrc.next('');
    this.utmSourceIdSrc.next('');
    this.invitingUserIdSrc.next('');
  }
}
