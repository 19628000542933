import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BUTTON_TYPE, FAN_AREA_ITEMS } from 'src/app/shared/enums';
import { FloatingMenuService } from './floating-menu.service';

@Component({
  selector: 'app-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingMenuComponent implements AfterViewInit, OnDestroy {
  @ViewChild('contentContainer', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;

  menuItems$ = this.floatingMenuService.menuItems$;
  activeMenuItem$ = this.floatingMenuService.activeMenuItem$;
  isDrawerOpen$ = this.floatingMenuService.isDrawerOpen$;

  BUTTON_TYPE = BUTTON_TYPE;

  constructor(
    private renderer: Renderer2,
    private floatingMenuService: FloatingMenuService
  ) {}

  ngAfterViewInit() {
    this.floatingMenuService.setRenderer(this.renderer);

    this.activeMenuItem$.subscribe((item) => {
      this.container.clear();
      if (item) {
        this.container.createComponent(item.component);
      }
    });
  }

  ngOnDestroy() {
    this.renderer.removeStyle(document.body, 'overflow');
  }

  openDrawer(id: FAN_AREA_ITEMS) {
    this.floatingMenuService.openDrawer(id);
  }

  closeDrawer() {
    this.floatingMenuService.closeDrawer();
  }

  clickOutside(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      this.closeDrawer();
    }
  }
}
