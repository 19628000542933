import { Component } from '@angular/core';

@Component({
  selector: 'auction-page',
  templateUrl: './auction-page.component.html',
  styleUrls: ['./auction-page.component.scss']
})
export class AuctionPageComponent {

}
