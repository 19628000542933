<ng-container
  *ngIf="{
    walletAddress: walletAddress$ | async,
    referralsList: referralsList$ | async
  } as ctx">
  <div class="header">
    <h3 class="title">Invite friends</h3>
    <button class="terms" (click)="openTermsModal()">
      <span class="icon icon-book-solid-1"></span> <span>How it works</span>
    </button>
  </div>

  <div class="invite">
    <div class="card card-copy">
      <h4 class="title">Earn GMRX and points for every friend you invite</h4>
      <div class="description">Get rewarded for referring a friend who subscribes.</div>

      <div class="copy__wrapper">
        <div class="copy__form" *ngIf="referralLink$ | async as referralLink" @fadeInOut>
          <input class="link-input" [class.success]="copied$ | async" type="text" [value]="referralLink" readonly />
          <app-button
            class="copy-button"
            [class.success]="copied$ | async"
            [buttonData]="{
              name: 'Copy',
              iconRight: 'icon-Copy-3'
            }"
            [cdkCopyToClipboard]="referralLink"
            (click)="copyEvent()"></app-button>
        </div>
      </div>
    </div>
    <div class="card card-referrals">
      <div class="card__header">
        <img src="assets/icons/trophy.svg" alt="" class="icon" />
        <span>Referrals</span>
      </div>
      <div class="card__value">{{ ctx.referralsList?.pagination?.totalResults }}</div>
    </div>
    <div class="card card-total">
      <div class="card__header">
        <img src="assets/icons/trophy.svg" alt="" class="icon" />
        <span>Total earned</span>
      </div>
      <div class="card__value">
        <span>{{ (userBalance$ | async) ?? 0 }}</span>
        <reward-coin></reward-coin>
      </div>
    </div>

    <div class="card card-wallet card-wallet__connected" *ngIf="ctx.walletAddress">
      <div class="card__content">
        <div class="card__header">
          <img src="assets/icons/trophy.svg" alt="" class="icon" />
          <span>Points are credited to your wallet</span>
        </div>
        <div class="card__value">
          <div class="wallet-address">
            {{ ctx.walletAddress | cutWalletAddress }}
          </div>
          <div class="divider"></div>
          <div class="reward">
            <span>{{ (userBalance$ | async) ?? 0 }}</span>
            <reward-coin></reward-coin>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-wallet" *ngIf="!ctx.walletAddress">
      <div class="card__content">
        <div class="card__header">
          <img src="assets/icons/trophy.svg" alt="" class="icon" />
          <span>Connect wallet to receive points</span>
        </div>
        <div class="card__value">
          <div class="reward">
            <span>{{ (userBalance$ | async) ?? 0 }}</span>
            <reward-coin></reward-coin>
          </div>
        </div>
      </div>
      <div class="card__actions">
        <app-button
          class="connect-button connect-button__desktop"
          [buttonData]="{
            name: 'Connect wallet'
          }"
          (click)="connectWallet()"></app-button>
        <app-button
          class="connect-button connect-button__mobile"
          [buttonData]="{
            name: 'Connect'
          }"
          (click)="connectWallet()"></app-button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="ctx.referralsList">
    <div class="referrals" *ngIf="ctx.referralsList.referrals.length" @fadeInOut>
      <h4 class="referrals__title">My referrals</h4>

      <div class="referrals__table">
        <div class="table-header">
          <div class="cell cell__name">Name</div>
          <div class="cell cell__subscription">Subscription</div>
          <div class="cell cell__reward">Reward</div>
          <div class="cell cell__status">Paid</div>
        </div>

        <div class="table-body">
          <div class="table-row" *ngFor="let referral of ctx.referralsList.referrals | paginate: (paginationConfig$ | async)!">
            <div class="cell cell__name">
              <img *ngIf="referral.avatarUrl" [src]="referral.avatarUrl" alt="avatar" class="avatar" />
              <img *ngIf="!referral.avatarUrl" src="assets/icons/default-user-image.png" alt="default avatar" class="avatar" />
              <span>{{ referral.username ?? '-' }}</span>
            </div>
            <div class="cell cell__subscription">
              <img
                src="/assets/icons/packages/{{ referral.tier.toLocaleLowerCase() }}.png"
                alt="status"
                class="sub-icon" /><span>{{ referral.tier.toLocaleLowerCase() }}</span>
            </div>
            <div class="cell cell__reward">
              <span>
                {{ referral.referralPointsReward }}
              </span>
              <reward-coin></reward-coin>
            </div>
            <div class="cell cell__status">
              <span
                class="icon {{ getStatusIcon(referral, !!ctx.walletAddress) }}"
                [tooltip]="getStatusText(referral, !!ctx.walletAddress)"
                [tooltipPosition]="'left'"></span>
            </div>
          </div>
        </div>
      </div>

      <pagination-controls
        *ngIf="(ctx.referralsList?.pagination)!.totalResults > (paginationConfig$ | async)!.itemsPerPage"
        [responsive]="false"
        [maxSize]="6"
        [previousLabel]="''"
        [nextLabel]="''"
        (pageChange)="onPageChange($event)"
        id="referrals"
        class="pagination"></pagination-controls>
    </div>
  </ng-container>
</ng-container>
