import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { LeaderBoardActivePeriodData, LeaderBoardItem, LeaderboardResponse, Response } from '../../interfaces';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {
  private leaderboardDataSrc = new BehaviorSubject<LeaderboardResponse | undefined>(undefined);
  leaderboardData$ = this.leaderboardDataSrc.asObservable();

  private meLeaderboardDataSrc = new BehaviorSubject<LeaderBoardItem | undefined>(undefined);
  meLeaderboardData$ = this.meLeaderboardDataSrc.asObservable();

  private leaderboardActivePeriodDataSrc = new BehaviorSubject<LeaderBoardActivePeriodData | undefined>(undefined);
  leaderboardActivePeriodData$ = this.leaderboardActivePeriodDataSrc.asObservable();

  constructor(private http: HttpClient) {}

  getLeaderboard(page = 1, size = 10): Observable<Response<LeaderboardResponse>> {
    return this.http
      .get<Response<LeaderboardResponse>>(`${environment.gaiminApi}/leaderboard`, {
        params: {
          page,
          size
        }
      })
      .pipe(tap((data) => this.leaderboardDataSrc.next(data.data)));
  }

  getLeaderboardById(id: number) {
    return this.http
      .get<Response<LeaderBoardItem>>(`${environment.gaiminApi}/leaderboard/${id}`)
      .pipe(tap((data) => this.meLeaderboardDataSrc.next(data.data)));
  }

  getLeaderboardActivePeriod() {
    return this.http
      .get<Response<LeaderBoardActivePeriodData>>(`${environment.gaiminApi}/leaderboard/period`)
      .pipe(tap((data) => this.leaderboardActivePeriodDataSrc.next(data.data)));
  }
}
