import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { BehaviorSubject, filter, Observable, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoginModalComponent } from '../../components/modals/login-modal/login-modal.component';
import { OnboardingModalComponent } from '../../components/modals/onboarding-modal/onboarding-modal.component';
import {
  Response,
  SubscriptionTypeInfo,
  TokensResponse,
  DpaEventEnrollmentData,
  UserSignInCredentials,
  UserSignUpCredentials
} from '../../interfaces';
import { COLOSSEUM_TIERS, USER_ROLES } from '../../enums';
import { AuthStateService } from './auth-state.service';
import { AuthRequestService } from './auth-request.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string | null = null;

  private currentUserRolesSrc = new BehaviorSubject<string[]>([]);
  private managerEncodedCredsSrc = new BehaviorSubject<string | null>(null);
  private acquireUserSubscriptionDataSrc = new BehaviorSubject<SubscriptionTypeInfo | null>({} as SubscriptionTypeInfo);
  private dpaEventEnrollmentDataSrc = new BehaviorSubject<DpaEventEnrollmentData>({} as DpaEventEnrollmentData);

  acquireUserSubscriptionData$ = this.acquireUserSubscriptionDataSrc.asObservable();
  dpaEventEnrollmentData$ = this.dpaEventEnrollmentDataSrc.asObservable();

  get managerEncodedCreds(): string | null {
    return this.managerEncodedCredsSrc.value;
  }
  get acquireUserSubscriptionData(): SubscriptionTypeInfo | null {
    return this.acquireUserSubscriptionDataSrc.value;
  }

  private logoutStarted: boolean = false;

  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    public oktaAuthStateService: OktaAuthStateService,
    private dialogService: MatDialog,
    private overlay: Overlay,
    private toastrService: ToastrService,
    private router: Router,
    private authStateService: AuthStateService,
    private authRequestService: AuthRequestService
  ) {
    this.oktaAuthStateService.authState$.subscribe((oktaAuthState) => {
      console.log('oktaAuthState:', oktaAuthState);
      if (!!oktaAuthState.isAuthenticated && !this.logoutStarted) {
        this.authStateService.setAccessToken(oktaAuthState.accessToken!.accessToken);
        this.authStateService.setRefreshToken(oktaAuthState.refreshToken!.refreshToken);
        this.checkEmptyProfileInfo();
        this.checkUtmLink();
        this.checkDpaEventEnrollment(true, false);
      }
    });

    this.authStateService.userRolesSnapshot && this.currentUserRolesSrc.next([this.authStateService.userRolesSnapshot]);
  }

  performRegisterRequest(dto: UserSignUpCredentials, shouldRedirect: boolean) {
    this.authRequestService
      .registerRequest(dto)
      .pipe(
        tap((response) => {
          this.handleAuthResponse(response, dto, shouldRedirect);
          this.checkUtmLink();
        })
      )
      .subscribe();
  }

  performLoginRequest(dto: UserSignInCredentials, shouldRedirect: boolean) {
    this.logoutStarted = false;
    this.authRequestService
      .loginRequest(dto)
      .pipe(
        tap((response) => {
          this.handleAuthResponse(response, dto, shouldRedirect);
        })
      )
      .subscribe();
  }

  acquireAccount(showSuccessPopup: boolean = false, tier?: COLOSSEUM_TIERS) {
    this.authRequestService
      .acquireUserSubscriptionRequest(tier!)
      .pipe(
        filter((response) => response.success && response.data !== null),
        tap((response) => {
          this.acquireUserSubscriptionDataSrc.next(response!.data!);
          if (showSuccessPopup) {
            this.toastrService.success(`Your account has been successfully changed to ${response!.data!.tier}`);
          }
          window.location.reload();
        })
      )
      .subscribe();
  }

  checkEmptyProfileInfo() {
    this.authRequestService
      .checkEmptyProfileInfoRequest()
      .pipe(
        filter((response) => response.success && !response.data),
        tap(() => {
          this.dialogService.open(OnboardingModalComponent, {
            panelClass: 'dialog-overlay-pane'
          });
        })
      )
      .subscribe();
  }

  checkUtmLink() {
    if (this.authStateService.utmSourceIdSnapshot !== '') {
      this.authRequestService
        .utmSourceIdRequest({ utmSourceId: this.authStateService.utmSourceIdSnapshot })
        .pipe(
          filter((response) => response.success),
          tap(() => {
            localStorage.removeItem('utmSourceId');
          })
        )
        .subscribe();
    }
  }

  updateSubscriptionInfo() {
    this.authRequestService
      .getUserSubscriptionTypeRequest()
      .pipe(
        filter((response) => response.success && response.data !== null),
        tap((response) => {
          this.acquireUserSubscriptionDataSrc.next(response!.data!);
        })
      )
      .subscribe();
  }

  checkDpaEventEnrollment(showConnectWalletToastr: boolean = false, showSuccessToastr: boolean = false) {
    this.authRequestService
      .dpaEventEnrollmentRequest()
      .pipe(
        filter((response) => response.success && response.data !== null),
        tap((response) => {
          this.dpaEventEnrollmentDataSrc.next(response.data!);
          const url = this.router.url;
          if (!url.includes('events/dpa-claim')) {
            if (response!.data!.hasDpaClaims && !response!.data!.isWalletConnected) {
              if (showConnectWalletToastr) {
                this.toastrService.success(
                  `You’ve already claimed your DPA! Please connect your wallet to receive it.`,
                  undefined,
                  {
                    timeOut: 0,
                    extendedTimeOut: 0,
                    tapToDismiss: false
                  }
                );
              }
              if (showSuccessToastr) {
                this.toastrService.success(
                  `Congratulations, your wallet is connected successfully. Your DPA will appear in your Fan Passport after the
              event!`,
                  undefined,
                  {
                    timeOut: 0,
                    extendedTimeOut: 0,
                    tapToDismiss: false
                  }
                );
              }
            }
          }
        })
      )
      .subscribe();
  }

  loginWithOkta() {
    this.logoutStarted = false;
    const redirectUrl = this.redirectUrl ? this.redirectUrl : '/content';
    this.oktaAuth.signInWithRedirect({ originalUri: redirectUrl }).then((signInResult) => {
      console.log('Okta sign in result', signInResult);
    });
  }

  async logout() {
    this.logoutStarted = true;

    await this.oktaAuth.isAuthenticated().then(async (isAuthenticated) => {
      if (isAuthenticated) {
        await this.oktaAuth.signOut();
      }
      this.clearData();
      this.router.navigateByUrl('/');
    });
  }

  performRefreshTokenRequest() {
    return this.authRequestService.refreshTokenRequest().pipe(
      tap((response) => {
        this.authStateService.setAccessToken(response.data!.accessToken);
        this.authStateService.setRefreshToken(response.data!.refreshToken);
        window.location.reload();
      })
    );
  }

  clearData() {
    this.authStateService.clearData();
    this.acquireUserSubscriptionDataSrc.next(null);
  }

  openLoginPopup(isSignUp: boolean = false, isFullScreen: boolean = false) {
    this.dialogService.open(LoginModalComponent, {
      panelClass: ['dialog-overlay-pane', isFullScreen ? 'full-screen' : ''],
      scrollStrategy: this.overlay.scrollStrategies.block(),
      data: {
        isSignUp,
        isFullScreen
      }
    });
  }

  closeLoginPopup() {
    this.dialogService.closeAll();
  }

  checkUserRights(userRoles: string[]) {
    return userRoles.some((roleToCheck) => this.currentUserRolesSrc.getValue().includes(roleToCheck));
  }

  getUserPermissions(credential: unknown): Observable<Response<USER_ROLES[]>> {
    const { email, password } = credential as { email: string; password: string };
    const credentials = `${email}:${password}`;
    const encodedCredentials = btoa(credentials);
    this.managerEncodedCredsSrc.next(encodedCredentials);
    this.authStateService.setManagerToken(encodedCredentials);

    return this.authRequestService.getUserPermissionsRequest(encodedCredentials).pipe(
      tap((userRolesResponse) => {
        let userRole = USER_ROLES.ROLE_USER;

        if (userRolesResponse.success) {
          const currentUserRoles = userRolesResponse.data;
          this.currentUserRolesSrc.next(currentUserRoles!);
          if (this.checkUserRights([USER_ROLES.ROLE_COLOSSEUM_MANAGER])) {
            userRole = USER_ROLES.ROLE_COLOSSEUM_MANAGER;
          }
        } else {
          this.currentUserRolesSrc.next([userRole]);
        }

        this.authStateService.setUserRoles(userRole);
      })
    );
  }

  private successAuthorize(tokenData: TokensResponse, showOnboardingPopup: boolean = false, shouldRedirect: boolean) {
    this.authStateService.setAccessToken(tokenData.accessToken);
    this.authStateService.setRefreshToken(tokenData.refreshToken);

    if (showOnboardingPopup) {
      this.dialogService.open(OnboardingModalComponent, {
        panelClass: 'dialog-overlay-pane'
      });
    }

    if (shouldRedirect) {
      this.router.navigateByUrl('/content');
    }
  }

  private handleAuthResponse(response: Response<any>, formValue: any, shouldRedirect: boolean) {
    if (!response.success && !response.data) return;

    const showOnboardingPopup = response.data.isEmptyProfileInfo;
    const loginData = response.data.token || response.data;
    this.successAuthorize(loginData, showOnboardingPopup, shouldRedirect);
    this.checkDpaEventEnrollment(true, false);
    this.getUserPermissions(formValue).subscribe();

    if (formValue.mailingList) {
      this.authRequestService.addToMailingList().subscribe();
    }
  }
}
