import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'reward-coin',
  templateUrl: './reward-coin.component.html',
  styleUrls: ['./reward-coin.component.scss'],
  standalone: true,
  imports: [CommonModule],
  animations: [
    trigger('jumpAnimation', [
      state('idle', style({ transform: 'translateY(0)' })),
      state('jump', style({ transform: 'translateY(0)' })),
      transition('idle => jump', [
        animate('120ms ease-out', style({ transform: 'translateY(-130%)' })),
        animate('100ms ease-out', style({ transform: 'translateY(0)' })),
        animate('90ms ease-out', style({ transform: 'translateY(-50%)' })),
        animate('80ms ease-out', style({ transform: 'translateY(0)' })),
        animate('60ms ease-out', style({ transform: 'translateY(-20%)' })),
        animate('50ms ease-out', style({ transform: 'translateY(0)' }))
      ])
    ])
  ]
})
export class RewardCoinComponent {
  coinState$ = new BehaviorSubject<'idle' | 'jump'>('idle');

  click() {
    if (this.coinState$.value === 'jump') {
      return;
    }

    this.coinState$.next('jump');

    setTimeout(() => {
      this.coinState$.next('idle');
    }, 500);
  }
}
