import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { map, tap } from 'rxjs';
import { FloatingMenuService } from 'src/app/core/layout/floating-menu/floating-menu.service';
import { FAN_AREA_ITEMS } from 'src/app/shared/enums';

interface ItemData {
  title: string;
  icon: string;
  disabled?: boolean;
  link?: string;
  cb?: () => void;
}

@Component({
  selector: 'how-to-earn-points',
  templateUrl: './how-to-earn-points.component.html',
  styleUrls: ['./how-to-earn-points.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HowToEarnPointsComponent {

  public isMobile$ = this.breakpointObserver.observe(['(max-width: 768px)'])
    .pipe(map((state) => state.matches))

  constructor(
    private floatingMenuService: FloatingMenuService,
    private breakpointObserver: BreakpointObserver
  ) { }

  items: ItemData[] = [
    {
      title: 'Invite friends',
      icon: 'icon-invite',
      cb: () => {
        this.floatingMenuService.openDrawer(FAN_AREA_ITEMS.REFERRALS);
      },
      link: '/fan-area/referrals'
    },
    {
      title: 'Match Predictions',
      icon: 'icon-trophy-solid-1',
      disabled: true
    },
    {
      title: 'Voting',
      icon: 'icon-voting',
      disabled: true
    },
    {
      title: 'Quizzes',
      icon: 'icon-quizz',
      disabled: true
    },
    {
      title: 'Player of the Month',
      icon: 'icon-Star',
      disabled: true
    }
  ];

  handleClick(cb?: () => void) {
    this.isMobile$.pipe(tap((isMobile) => {
      !isMobile && cb?.();
    })).subscribe()
  }
}
