import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  LoginResponse,
  UserSignInCredentials,
  Response,
  UserSignUpCredentials,
  TokensResponse,
  SubscriptionTypeInfo,
  ChangePasswordRequest,
  DpaEventEnrollmentData,
  UtmSourceRequest,
  UtmSourceResponse
} from '../../interfaces';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AuthStateService } from './auth-state.service';
import { COLOSSEUM_TIERS, USER_ROLES } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class AuthRequestService {
  constructor(
    private readonly http: HttpClient,
    private readonly authStateService: AuthStateService
  ) {}

  loginRequest(userData: UserSignInCredentials): Observable<Response<LoginResponse>> {
    return this.http.post<Response<LoginResponse>>(`${environment.gaiminApi}/users/auth`, {
      ...userData,
      fromColosseum: true
    });
  }

  registerRequest(userData: UserSignUpCredentials): Observable<Response<TokensResponse>> {
    return this.http.post<Response<TokensResponse>>(`${environment.gaiminApi}/users/register`, {
      ...userData,
      invitingUserId: this.authStateService.invitingUserIdSnapshot,
      fromColosseum: true
    });
  }

  checkEmptyProfileInfoRequest(): Observable<Response<boolean>> {
    return this.http.get<Response<boolean>>(environment.gaiminApi + '/users/me/empty-profile-info');
  }

  getUserSubscriptionTypeRequest(): Observable<Response<SubscriptionTypeInfo>> {
    return this.http.get<any>(environment.gaiminApi + '/colosseum-subscription/active');
  }

  acquireUserSubscriptionRequest(tier: COLOSSEUM_TIERS): Observable<Response<SubscriptionTypeInfo>> {
    return this.http.post<Response<SubscriptionTypeInfo>>(
      `${environment.gaiminApi}/colosseum-subscription/acquire?tier=${tier}`,
      {}
    );
  }

  refreshTokenRequest(): Observable<Response<TokensResponse>> {
    return this.http.post<Response<TokensResponse>>(`${environment.gaiminApi}/users/auth/refresh`, {
      refreshToken: this.authStateService.refreshTokenSnapshot
    });
  }

  changePasswordRequest(newUserData: ChangePasswordRequest): Observable<Response<any>> {
    return this.http.post<Response<any>>(`${environment.gaiminApi}/users/me/change-password`, newUserData);
  }

  dpaEventEnrollmentRequest(): Observable<Response<DpaEventEnrollmentData>> {
    return this.http.get<Response<DpaEventEnrollmentData>>(`${environment.gaiminApi}/dpa-event/user-enrollment`);
  }

  utmSourceIdRequest(dto: UtmSourceRequest): Observable<Response<UtmSourceResponse>> {
    return this.http.post<Response<UtmSourceResponse>>(`${environment.gaiminApi}/users/utm`, dto);
  }

  getUserPermissionsRequest(encodedCredentials: string) {
    const headers = new HttpHeaders().set('Authorization', `Basic ${encodedCredentials}`).set('skipAuth', 'true');
    return this.http.get<Response<USER_ROLES[]>>(`${environment.gaiminApi}/admin/roles`, {
      headers
    });
  }

  addToMailingList() {
    return this.http.post(`${environment.gaiminApi}/users/mailing-list`, null);
  }
}
