import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PasswordInputComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true
})
export class PasswordInputComponent implements ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() id: string = '';

  private readonly HIDE_PASSWORD_DELAY: number = 3000;

  public isPasswordVisible$ = new BehaviorSubject<boolean>(false);

  private timeOut?: ReturnType<typeof setTimeout>;

  public value: string = '';

  private onChange: (value?: any) => void = () => {};
  private onTouched: (value?: any) => void = () => {};

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public handleInputChange({ target: { value } }: any): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }

  public togglePasswordVisibility(): void {
    this.isPasswordVisible$.next(!this.isPasswordVisible$.value);

    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.isPasswordVisible$.next(false);
    }, this.HIDE_PASSWORD_DELAY);
  }
}
