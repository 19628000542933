import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  standalone: true
})
export class EmptyComponent {
  @Input() message: string = 'There were no results found matching your query';
}
