import { DgaNftData } from '../app/shared/intarfaces';

export default [
  {
    name: 'Dota 2 Birmingham 2024',
    tokenId: 1,
    description:
      'A unqie memorabilia to help fans relive the exciting plays and moments that occurred during ESL One Birmingham 2024',
    imageUrl: 'assets/icons/dga-nft/dota_esl_birmingham_2024_dpa_tokenId1.png',
    maxSupply: 500,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Common'
      }
    ]
  },
  {
    name: 'Dota 2 Birmingham 2024 (Rare)',
    tokenId: 2,
    description:
      'A unqie memorabilia to help fans relive the exciting plays and moments that occurred during ESL One Birmingham 2024',
    imageUrl: 'assets/icons/dga-nft/dota_esl_birmingham_2024_rare_dpa_tokenId2.png',
    maxSupply: 11,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Rare'
      }
    ]
  },
  {
    name: 'Dota 2 Bucharest 2024',
    tokenId: 3,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during PGL Wallachia Season 1',
    imageUrl: 'assets/icons/dga-nft/dota_pgl_bucharest_2024_dpa_tokenId3.png',
    maxSupply: 490,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Common'
      }
    ]
  },
  {
    name: 'Dota 2 Bucharest 2024 (Rare)',
    tokenId: 4,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during PGL Wallachia Season 1',
    imageUrl: 'assets/icons/dga-nft/dota_pgl_bucharest_2024_dpa_rare_tokenId4.png',
    maxSupply: 10,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Rare'
      }
    ]
  },
  {
    name: 'Counter-Strike 2 Ulaanbaatar 2024',
    tokenId: 5,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during MESA Nomadic Masters Spring 2024',
    imageUrl: 'assets/icons/dga-nft/ulaanbataar_mongolia_dpa_tokenId5.png',
    maxSupply: 490,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Common'
      }
    ]
  },
  {
    name: 'Counter-Strike 2 Ulaanbaatar 2024 (Rare)',
    tokenId: 6,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during MESA Nomadic Masters Spring 2024',
    imageUrl: 'assets/icons/dga-nft/ulaanbataar_mongolia_dpa_rare_tokenId6.png',
    maxSupply: 10,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Rare'
      }
    ]
  },
  {
    name: 'PUBG Mobile Katowice 2024',
    tokenId: 7,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during PUBG Mobile Super League - Europe, Middle East & Africa Spring 2024',
    imageUrl: 'assets/icons/dga-nft/pubgm_katowice_pmsl_emea_tokenId7.png',
    maxSupply: 490,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Common'
      }
    ]
  },
  {
    name: 'PUBG Mobile Katowice 2024 (Rare)',
    tokenId: 8,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during PUBG Mobile Super League - Europe, Middle East & Africa Spring 2024',
    imageUrl: 'assets/icons/dga-nft/pubgm_katowice_pmsl_emea_rare_tokenId8.png',
    maxSupply: 10,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Rare'
      }
    ]
  },
  {
    name: 'Rocket League London 2024',
    tokenId: 9,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during Rocket League Championship Series 2024 - Major 2: London',
    imageUrl: 'assets/icons/dga-nft/rocket_league_london_major_2024_dpa_tokenId9.png',
    maxSupply: 675,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Common'
      }
    ]
  },
  {
    name: 'Rocket League London 2024 (Rare)',
    tokenId: 10,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during Rocket League Championship Series 2024 - Major 2: London',
    imageUrl: 'assets/icons/dga-nft/rocket_league_london_major_2024_dpa_rare_tokenId10.png',
    maxSupply: 10,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Rare'
      }
    ]
  },
  {
    name: 'Gaimin Gladiators Riyadh 2024',
    tokenId: 11,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during the Esports World Cup 2024',
    imageUrl: 'assets/icons/dga-nft/dota_ewc_riyadh_masters_2024_dpa_tokenId11.jpg',
    maxSupply: 490,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Common'
      }
    ]
  },
  {
    name: 'Gaimin Gladiators Riyadh 2024 (Rare)',
    tokenId: 12,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during the Esports World Cup 2024',
    imageUrl: 'assets/icons/dga-nft/dota_ewc_riyadh_masters_2024_rare_dpa_tokenId12.jpg',
    maxSupply: 10,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Rare'
      }
    ]
  },
  {
    name: 'Dota 2 Copenhagen 2024',
    tokenId: 13,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during The International 2024',
    imageUrl: 'assets/icons/dga-nft/dota_TI13_2024_dpa_tokenId13.jpg',
    maxSupply: 490,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Common'
      }
    ]
  },
  {
    name: 'Dota 2 Copenhagen 2024 (Rare)',
    tokenId: 14,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during The International 2024',
    imageUrl: 'assets/icons/dga-nft/dota_TI13_2024_dpa_rare_tokenId14.jpg',
    maxSupply: 10,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Rare'
      }
    ]
  },
  {
    name: 'Dota 2 Belgrade 2024',
    tokenId: 15,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during BetBoom Dacha Belgrade 2024',
    imageUrl: 'assets/icons/dga-nft/DOTA_BB_DACHA_DPA_STANDARD_tokenId15.jpg',
    maxSupply: 490,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Common'
      }
    ]
  },
  {
    name: 'Dota 2 Belgrade 2024 (Rare)',
    tokenId: 16,
    description:
      'A unique memorabilia to help fans relive the exciting plays and moments that occurred during BetBoom Dacha Belgrade 2024',
    imageUrl: 'assets/icons/dga-nft/DOTA_BB_DACHA_DPA_RARE_tokenId16.jpg',
    maxSupply: 10,
    attributes: [
      {
        trait_type: 'Rarity',
        name: 'Rarity',
        value: 'Rare'
      }
    ]
  },

] as DgaNftData[];
