import { Component } from '@angular/core';
import { EmailConfirmationComponent } from '../email-confirmation/email-confirmation.component';
import { HeaderProfileComponent } from '../header-profile/header-profile.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [EmailConfirmationComponent, HeaderProfileComponent],
  standalone: true
})
export class HeaderComponent {
  constructor() {}
}
