<div class="floating-menu" [ngClass]="{ active: isDrawerOpen$ | async }">
  <button
    class="floating-menu__item"
    *ngFor="let item of menuItems$ | async"
    [ngClass]="{ active: item.active, notification: item.badge | async }"
    (click)="openDrawer(item.id)">
    <img [src]="'assets/icons/floating-menu/' + item.icon" [alt]="item.title" />
  </button>
</div>

<div class="drawer__wrapper" (click)="clickOutside($event)" [ngClass]="{ active: isDrawerOpen$ | async }">
  <div class="drawer" [ngClass]="{ active: isDrawerOpen$ | async }" #drawer>
    <span class="icon-Close close-icon" title="Close" (click)="closeDrawer()"></span>

      <ng-container #contentContainer></ng-container>
  </div>
</div>
