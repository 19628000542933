import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutWalletAddress',
  standalone: true
})
export class CutWalletAddressPipe implements PipeTransform {
  constructor() {}

  transform(address: string | null): string {
    if (!address) {
      return '';
    }
    return address.slice(0, 4) + '....' + address.slice(-4);
  }
}
