import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { filter, Subscription, switchMap, tap, timer } from 'rxjs';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { EmptyComponent } from '../../empty/empty.component';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  imports: [CommonModule, EmptyComponent, NotificationItemComponent],
  standalone: true
})
export class NotificationListComponent implements OnInit {
  @AutoUnsubscribe()
  private notificationSub: Subscription = {} as Subscription;
  
  constructor(public notificationService: NotificationService) { }

  ngOnInit(): void {
    this.notificationSub = timer(15_000).pipe(
      filter(() => this.notificationService.hasNewNotifications$.value),
      switchMap(() => this.notificationService.readNotifications()),
      tap(() => this.notificationService.hasNewNotifications$.next(false)),
      switchMap(() => this.notificationService.getNotifications())
    ).subscribe();
  }
}
