<svg
  width="15"
  height="14"
  viewBox="0 0 15 14"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [@jumpAnimation]="coinState$ | async"
  (click)="click()"
>
  <path
    d="M14.5029 7.45882C14.4559 8.37647 14.2206 9.24705 13.8677 10.0235C13.7578 10.2745 13.6324 10.5176 13.4912 10.7529C13.2324 11.2235 12.8794 11.6471 12.5265 12.0471C12.5265 12.0471 12.5186 12.0471 12.5029 12.0471C12.3461 12.2196 12.1814 12.3765 12.0088 12.5176C11.9931 12.5176 11.9853 12.5255 11.9853 12.5412C11.7343 12.7451 11.4677 12.9255 11.1853 13.0823C11.1853 13.0823 11.1775 13.0902 11.1618 13.1059C10.3618 13.5529 9.44412 13.8353 8.50295 13.8588H8.47942C8.43236 13.8588 8.3853 13.8588 8.33824 13.8588C8.27549 13.8588 8.21275 13.8588 8.15001 13.8588C8.11863 13.8588 8.07942 13.8588 8.03236 13.8588C8.00099 13.8588 7.96961 13.8588 7.93824 13.8588C7.90687 13.8431 7.8755 13.8353 7.84412 13.8353C7.81275 13.8353 7.78922 13.8353 7.77353 13.8353C7.45981 13.8039 7.14608 13.7412 6.83236 13.6471C6.64412 13.6 6.45589 13.5373 6.26765 13.4588C4.07942 12.5647 2.45589 10.2588 2.31471 7.45882C2.29903 6.95686 2.3304 6.46274 2.40883 5.97647C2.76177 3.55294 4.24412 1.52941 6.29118 0.63529C6.76177 0.447055 7.24804 0.313725 7.75 0.235294C7.76569 0.235294 7.78138 0.235294 7.79707 0.235294C7.81275 0.235294 7.82844 0.235294 7.84412 0.235294C8.04805 0.203922 8.25197 0.188232 8.45589 0.188232H8.50295C8.53432 0.188232 8.57354 0.188232 8.6206 0.188232C10.3618 0.235291 11.9147 1.08235 12.9971 2.42353C13.0127 2.4549 13.0284 2.47843 13.0441 2.49411C13.0441 2.5098 13.0441 2.51764 13.0441 2.51764C14.0794 3.83529 14.6441 5.57647 14.5029 7.45882Z"
    fill="url(#paint0_linear_4368_11633)"
  />
  <path
    d="M8.73817 13.8353L8.50287 13.8588H8.47934L7.23228 13.9294H7.20875L6.64405 13.9765L6.29111 14H6.26758V13.9765V13.8588V13.6941V13.4588L6.29111 5.81176V0.63529V0.282349V0.0235237V0L7.13817 0.0705854L8.62052 0.188232V0.305879V0.32941V0.470588V0.541174V0.564704L8.64405 4.25882L8.71464 13.0823L8.73817 13.5059V13.5529V13.7176V13.7882V13.8353Z"
    fill="url(#paint1_linear_4368_11633)"
  />
  <path
    d="M12.5971 7.45882C12.55 8.4 12.3383 9.29412 11.9853 10.0941C11.9853 10.0941 11.9853 10.102 11.9853 10.1176C11.9618 10.1412 11.9383 10.2353 11.8677 10.3529C11.8363 10.4157 11.8049 10.4784 11.7736 10.5412C11.4912 11.1059 11.1147 11.6235 10.6912 12.0706C10.6441 12.1333 10.5892 12.1961 10.5265 12.2588C9.98532 12.7765 9.39709 13.2 8.73827 13.5059C8.42454 13.6471 8.10297 13.7569 7.77356 13.8353C7.67944 13.851 7.58532 13.8745 7.49121 13.9059C7.41277 13.9216 7.33434 13.9294 7.25591 13.9294C7.25591 13.9294 7.24807 13.9294 7.23238 13.9294C6.96571 13.9765 6.6912 14 6.40885 14C6.36179 14 6.32258 14 6.2912 14H6.26768C5.70297 13.9765 5.16179 13.8823 4.66768 13.7176C4.58924 13.702 4.51865 13.6784 4.45591 13.6471C4.44022 13.6471 4.42454 13.6392 4.40885 13.6235C3.84415 13.4118 3.27944 13.1059 2.78532 12.7059C2.39317 12.3922 2.04023 12.0392 1.7265 11.6471C0.832382 10.5412 0.244146 9.08235 0.126499 7.45882C0.0559109 6.23529 0.244145 5.05882 0.691204 4.02352C0.691204 4.02352 0.706893 3.98431 0.738265 3.90588C1.46768 2.21176 2.80885 0.870585 4.47944 0.305879C4.47944 0.305879 4.48728 0.305879 4.50297 0.305879C4.59709 0.274507 4.69905 0.243136 4.80885 0.211763C4.85591 0.196077 4.90297 0.180388 4.95003 0.164702C5.05983 0.149015 5.16179 0.133333 5.25591 0.117647C5.30297 0.101961 5.35787 0.0941162 5.42062 0.0941162C5.70297 0.0470574 5.99316 0.0156863 6.2912 0C6.58924 0.0156863 6.8716 0.0392128 7.13826 0.0705854C7.37356 0.101958 7.60885 0.156863 7.84415 0.235294C7.84415 0.235294 7.85199 0.235294 7.86767 0.235294C7.94611 0.25098 8.03238 0.274507 8.1265 0.305879C8.1265 0.321566 8.13434 0.32941 8.15003 0.32941C8.30689 0.376469 8.46375 0.431367 8.62062 0.494112C11.0912 1.59999 12.7383 4.37647 12.5971 7.45882Z"
    fill="url(#paint2_linear_4368_11633)"
  />
  <g style="mix-blend-mode: multiply" opacity="0.2">
    <path
      d="M10.1969 2.04697C10.1499 2.35285 9.60869 3.34108 8.64398 4.25873C8.03222 4.8705 7.23222 5.45873 6.29104 5.81167C5.8832 5.96854 5.45183 6.07834 4.99692 6.14108C3.91457 6.25873 3.06751 6.16462 2.40869 5.97638C1.1381 5.62344 0.596924 4.91756 0.596924 4.58815C0.596924 4.58815 1.02045 3.4352 1.91457 2.28226C2.54987 1.48226 3.39692 0.705793 4.47928 0.305793C4.47928 0.305793 4.48712 0.305793 4.50281 0.305793C4.59693 0.274421 4.69888 0.24305 4.80869 0.211677C4.84006 0.195991 4.87143 0.188146 4.90281 0.188146C4.91849 0.188146 4.93418 0.180302 4.94986 0.164615C5.05967 0.148929 5.16163 0.133247 5.25575 0.117561C5.30281 0.101875 5.35771 0.09403 5.42045 0.09403C5.71849 0.0469712 6.00869 0.0234375 6.29104 0.0234375C6.80869 0.0234375 7.27928 0.117561 7.72634 0.235208C7.72634 0.235208 7.73418 0.235208 7.74986 0.235208C8.06359 0.329326 8.35379 0.439128 8.62045 0.564618C8.74594 0.627363 8.86359 0.690106 8.9734 0.752851C9.09889 0.831282 9.23222 0.917558 9.37339 1.01168C9.9381 1.41168 10.244 1.8352 10.1969 2.04697Z"
      fill="#F42774"
    />
  </g>
  <g style="mix-blend-mode: soft-light">
    <path
      d="M0.85584 10.047C1.13819 10.447 5.18525 8.70581 7.09113 8.16464C9.02055 7.64699 12.0323 8.2117 12.2911 7.99993C12.5735 7.76464 12.4323 4.58817 11.3264 3.0117C10.2205 1.38817 10.2205 3.10581 9.02055 3.90581C7.8676 4.68228 3.18525 6.02346 2.17348 5.92934C1.18525 5.83522 0.832308 5.38816 0.455837 5.43522C0.102896 5.50581 -0.532395 7.99993 0.85584 10.047Z"
      fill="white"
    />
  </g>
  <path
    d="M10.3618 7.45869C10.2912 9.81163 8.64419 11.6705 6.64419 11.8352C6.55007 11.8508 6.44811 11.8587 6.33831 11.8587C4.19713 11.8587 2.36184 9.92928 2.24419 7.45869C2.12654 4.87045 3.93831 2.65869 6.26772 2.65869C6.37752 2.65869 6.47948 2.66654 6.5736 2.68222C8.76184 2.87046 10.4324 4.9881 10.3618 7.45869Z"
    fill="url(#paint3_linear_4368_11633)"
  />
  <path
    d="M10.3618 7.45884C10.2912 9.81178 8.64412 11.6706 6.64412 11.8353C4.62059 11.6706 2.95001 9.81178 2.85589 7.45884C2.76177 4.98825 4.40883 2.87061 6.57353 2.68237C8.76177 2.87061 10.4324 4.98825 10.3618 7.45884Z"
    fill="#FF6B04"
  />
  <path
    d="M8.54999 8.54107C8.54999 8.54107 8.8794 10.0234 8.64411 10.5175C8.61273 10.5803 8.57352 10.6195 8.52646 10.6352C8.4794 10.6822 7.96175 10.6822 7.89117 10.7058C7.56175 10.7058 7.5147 10.447 7.13823 10.2352C7.07548 10.1881 7.01273 10.1489 6.94999 10.1175C6.78528 9.99989 6.66764 9.9293 6.66764 9.9293C6.66764 9.9293 6.14999 10.3058 5.65587 10.5175C5.5147 10.5881 5.34999 10.6352 5.23234 10.6587C5.11469 10.7058 4.43234 10.7058 4.40881 10.7058C3.98528 10.4234 4.7147 8.61165 4.73823 8.54107C4.73823 8.54107 3.04411 7.24695 3.11469 6.63518C3.23234 5.99989 5.30293 5.9293 5.30293 5.9293C5.30293 5.9293 5.49117 3.88224 6.03234 3.43518C6.10293 3.36459 6.4794 3.43518 6.54999 3.43518C6.73823 3.43518 6.94999 3.67048 7.13823 4.02342C7.56176 4.77636 7.86764 5.9293 7.86764 5.9293C7.86764 5.9293 8.14999 5.9293 8.4794 5.97636C8.8794 6.02342 9.37352 6.094 9.70293 6.25871C9.91469 6.35283 10.0559 6.47048 10.0794 6.63518C10.15 7.03518 9.42058 7.7646 8.94999 8.18813C8.7147 8.39989 8.54999 8.54107 8.54999 8.54107Z"
    fill="url(#paint4_linear_4368_11633)"
  />
  <path
    d="M9.25581 7.38825C9.24012 7.41962 9.21659 7.44315 9.18522 7.45883C9.04404 7.62354 8.87934 7.78825 8.73816 7.95295C8.64405 8.04707 8.54992 8.1255 8.45581 8.18825C8.24404 8.40001 8.05581 8.54119 8.05581 8.54119C8.05581 8.54119 8.14992 8.94119 8.22051 9.41178C8.26757 9.83531 8.2911 10.2824 8.17346 10.5177C8.14208 10.5647 8.10287 10.6039 8.05581 10.6353C8.00875 10.6667 7.95385 10.6902 7.8911 10.7059C7.56169 10.7059 7.04404 10.4471 6.66757 10.2353C6.60483 10.1882 6.54208 10.149 6.47934 10.1177C6.31463 10 6.19699 9.92942 6.19699 9.92942C6.19699 9.92942 5.67934 10.3059 5.18522 10.5177C5.04404 10.5882 4.90287 10.6353 4.76169 10.6588C4.65189 10.6902 4.54992 10.6981 4.45581 10.6824C4.42443 10.6667 4.39306 10.651 4.36169 10.6353C4.10287 10.4706 4.1264 9.69413 4.17345 9.15295C4.19698 8.98825 4.22051 8.84707 4.22051 8.75295C4.24404 8.6353 4.26757 8.56472 4.26757 8.54119C4.26757 8.54119 3.27934 7.78825 2.85581 7.17648C2.85581 7.17648 2.85581 7.16864 2.85581 7.15295C2.73816 7.01178 2.66757 6.8706 2.64404 6.75295C2.64404 6.70589 2.64404 6.66668 2.64404 6.6353C2.64404 6.57256 2.66757 6.50982 2.71463 6.44707C2.90287 6.23531 3.3264 6.11766 3.74992 6.04707C4.2911 5.92942 4.80875 5.92942 4.80875 5.92942C4.80875 5.92942 4.85581 5.69413 4.94993 5.38825C5.16169 4.65884 5.58522 3.4353 6.03228 3.4353C6.24405 3.4353 6.45581 3.6706 6.64404 4.02354C6.76169 4.2353 6.87934 4.49413 6.97346 4.75295C7.23228 5.36472 7.39698 5.92942 7.39698 5.92942C7.39698 5.92942 7.65581 5.92942 8.00875 5.97648C8.38522 6.02354 8.87934 6.09413 9.23228 6.25883C9.42051 6.35295 9.56169 6.4706 9.58522 6.6353C9.63228 6.82354 9.46757 7.1059 9.25581 7.38825Z"
    fill="url(#paint5_linear_4368_11633)"
  />
  <path
    d="M0.432249 9.12946C0.244014 8.30593 2.17343 9.34123 0.902838 10.3059C0.902838 10.3059 0.596955 9.76476 0.432249 9.12946Z"
    fill="white"
  />
  <g style="mix-blend-mode: soft-light">
    <path
      d="M8.50286 0.188345C7.96168 0.141287 11.1382 1.34129 11.8911 3.50599C12.597 5.57658 14.1499 4.91776 14.1499 4.91776C14.1499 4.91776 12.9264 0.376581 8.50286 0.188345Z"
      fill="white"
    />
  </g>
  <g style="mix-blend-mode: multiply" opacity="0.7">
    <path
      d="M13.8676 10.0235C13.8205 10.1882 13.7029 10.4471 13.4911 10.7529C13.2323 11.1765 12.8323 11.6941 12.3852 12.1412C12.2597 12.2823 12.1342 12.4078 12.0088 12.5176C11.9931 12.5176 11.9852 12.5255 11.9852 12.5412C11.7264 12.7529 11.444 12.9412 11.1852 13.0823C11.1852 13.0823 11.1774 13.0902 11.1617 13.1059C10.3146 13.5294 9.34993 13.7176 8.73817 13.7882C8.45582 13.8353 8.24405 13.8588 8.14993 13.8588C8.10287 13.8588 8.07934 13.8588 8.07934 13.8588C8.06366 13.8588 8.04797 13.8588 8.03229 13.8588C8.0166 13.8588 8.00091 13.851 7.98522 13.8353C7.93817 13.8118 8.24405 13.7647 8.73817 13.5529C9.5617 13.2235 10.9264 12.4471 11.7735 10.5412C11.7735 10.5412 13.4441 9.71764 13.8441 9.78823C13.9146 9.81176 13.9146 9.88234 13.8676 10.0235Z"
      fill="#DE376D"
    />
  </g>
  <path
    d="M8.12646 0.305908C8.12646 0.305908 11.7971 1.15297 12.6912 5.50591C13.5147 9.52944 10.2912 12.5647 10.2912 12.5647C10.2912 12.5647 12.6206 9.81179 12.3147 5.92944C11.9618 1.7412 8.12646 0.305908 8.12646 0.305908Z"
    fill="#FFE016"
  />
  <path
    d="M12.6206 5.55299C12.3618 5.20005 11.75 7.17652 12.4559 7.55299C12.7853 7.7177 13.0912 6.25887 12.6206 5.55299Z"
    fill="white"
  />
  <g style="mix-blend-mode: soft-light" opacity="0.5">
    <path
      d="M11.9851 10.1177C11.9616 10.1412 11.9381 10.2353 11.8675 10.353C11.6793 10.7059 11.3028 11.3883 10.691 12.0706C10.1969 12.6353 9.53809 13.1765 8.73809 13.553C8.45574 13.6628 8.1577 13.7569 7.84397 13.8353C7.76554 13.851 7.68711 13.8667 7.60868 13.8824C7.49888 13.8981 7.38123 13.9137 7.25574 13.9294C7.24005 13.9294 7.22437 13.9294 7.20868 13.9294C7.19299 13.9451 7.17731 13.953 7.16162 13.953C6.98907 13.9687 6.81652 13.9765 6.64397 13.9765C6.51848 13.9765 6.39299 13.9765 6.2675 13.9765C5.56162 13.953 4.94985 13.8118 4.45574 13.6471C4.44005 13.6471 4.42436 13.6392 4.40868 13.6236C3.44397 13.2941 2.9028 12.8 2.78515 12.7059C2.64397 12.5883 3.44397 13.153 4.6675 13.4824C5.13809 13.6 5.67927 13.6941 6.2675 13.6941C6.45574 13.6941 6.64397 13.6785 6.83221 13.6471C6.89495 13.6471 6.94985 13.6392 6.99691 13.6236C7.63221 13.5294 8.19691 13.3412 8.71456 13.0824C10.7381 12.0941 11.891 10.2589 11.9851 10.1177Z"
      fill="white"
    />
  </g>
  <g style="mix-blend-mode: soft-light" opacity="0.5">
    <path
      d="M9.25581 7.38823C9.24012 7.4196 9.21659 7.44313 9.18522 7.45881C9.04404 7.62352 8.87934 7.78823 8.73816 7.95293C8.1264 7.97646 7.53816 8.02352 7.02052 8.16469C6.2911 8.37646 5.20875 8.77646 4.17345 9.15293C4.19698 8.98823 4.22051 8.84705 4.22051 8.75293C4.24404 8.63528 4.26757 8.5647 4.26757 8.54117C4.26757 8.54117 3.27934 7.78823 2.85581 7.17646C2.85581 7.17646 2.85581 7.16862 2.85581 7.15293C2.73816 7.01176 2.66757 6.87058 2.64404 6.75293C2.64404 6.70587 2.64404 6.66666 2.64404 6.63528C2.64404 6.57254 2.66757 6.5098 2.71463 6.44705C2.90287 6.23529 3.3264 6.11764 3.74992 6.04705C4.2911 5.9294 4.80875 5.9294 4.80875 5.9294C4.80875 5.9294 4.85581 5.69411 4.94993 5.38823C5.63228 5.19999 6.33816 4.98822 6.97346 4.75293C7.23228 5.36469 7.39698 5.9294 7.39698 5.9294C7.39698 5.9294 7.65581 5.9294 8.00875 5.97646C8.38522 6.02352 8.87934 6.0941 9.23228 6.25881C9.42051 6.35293 9.56169 6.47058 9.58522 6.63528C9.63228 6.82352 9.46757 7.10587 9.25581 7.38823Z"
      fill="white"
    />
  </g>
  <path
    d="M3.65576 10.7058C3.65576 10.7058 6.59694 12.8235 8.90282 10.8235C8.90282 10.8235 6.36164 13.3176 3.65576 10.7058Z"
    fill="#FFE016"
  />
  <g style="mix-blend-mode: multiply" opacity="0.5">
    <path
      d="M2.36182 5.97634C2.52652 6.75281 3.16181 4.21164 5.75005 3.41164C8.36181 2.58811 10.2206 6.11752 10.2206 6.11752C10.2206 6.11752 9.79711 2.65869 6.17358 2.65869C3.32652 2.65869 2.36182 5.97634 2.36182 5.97634Z"
      fill="#D42F55"
    />
  </g>
  <g style="mix-blend-mode: multiply" opacity="0.7">
    <path
      d="M8.55003 8.54116H8.05591C8.05591 8.54116 8.95003 7.78822 9.42061 7.17646C9.8912 6.54116 10.0324 6.94116 10.0324 6.94116C10.0324 6.94116 9.53826 7.88234 8.55003 8.54116Z"
      fill="#DE376D"
    />
  </g>
  <path
    d="M7.74988 5.95292C7.74988 5.95292 7.39694 6.16468 7.74988 6.56468C8.07929 6.94115 9.044 6.61174 9.16164 6.35292C9.27929 6.11763 8.22047 5.92939 7.74988 5.95292Z"
    fill="white"
  />
  <g style="mix-blend-mode: soft-light">
    <path
      d="M9.20874 13.3647C8.97345 13.5059 10.2911 13.5294 10.8558 13.2471C11.397 12.9647 12.197 12.4236 12.3146 12.0706C12.4323 11.7412 11.6793 11.7883 11.2087 11.9059C10.7617 12 10.3146 12.7294 9.20874 13.3647Z"
      fill="white"
    />
  </g>
  <path
    d="M12.456 9.24712C12.0089 9.10595 11.3971 9.97654 11.703 10.706C12.0324 11.4118 13.4677 9.55301 12.456 9.24712Z"
    fill="url(#paint6_linear_4368_11633)"
  />
  <g style="mix-blend-mode: multiply" opacity="0.7">
    <path
      d="M11.7501 9.85893C11.7266 10.3766 12.3854 10.4236 12.6442 10.1413C12.9031 9.85893 12.8795 9.24716 12.3854 9.22363C11.9148 9.22363 11.7501 9.85893 11.7501 9.85893Z"
      fill="#DE376D"
    />
  </g>
  <path
    d="M11.6323 10.306C11.7735 10.0001 12.15 10.3766 12.4088 10.4001C12.6441 10.4236 12.2206 10.9413 11.9382 10.8707C11.6794 10.7766 11.6323 10.306 11.6323 10.306Z"
    fill="#FF9500"
  />
  <path
    d="M10.9734 9.45888C10.5264 9.34123 10.0793 9.97652 10.2676 10.3765C10.4558 10.8001 11.7499 9.69417 10.9734 9.45888Z"
    fill="url(#paint7_linear_4368_11633)"
  />
  <g style="mix-blend-mode: multiply" opacity="0.7">
    <path
      d="M10.7851 9.45885C10.4792 9.52944 10.3616 9.9765 10.4792 10.0941C10.6204 10.2353 11.2086 10.0706 11.2321 9.78826C11.2321 9.48238 10.9027 9.41179 10.7851 9.45885Z"
      fill="#DE376D"
    />
  </g>
  <path
    d="M10.2205 10.2355C10.3146 9.85898 10.4793 10.2355 10.7146 10.2825C10.9499 10.3296 10.244 10.7531 10.2205 10.2355Z"
    fill="#FF9500"
  />
  <path
    d="M3.39695 3.92915C3.02048 3.8115 2.69107 4.32915 2.85578 4.68209C3.04401 5.03503 4.00872 4.11738 3.39695 3.92915Z"
    fill="url(#paint8_linear_4368_11633)"
  />
  <g style="mix-blend-mode: multiply" opacity="0.7">
    <path
      d="M3.23232 3.90574C2.99703 3.97633 2.92644 4.32928 3.02056 4.44692C3.13821 4.56457 3.58527 4.42339 3.58527 4.1881C3.60879 3.92928 3.32644 3.88221 3.23232 3.90574Z"
      fill="#DE376D"
    />
  </g>
  <path
    d="M2.83228 4.56464C2.87933 4.23523 3.02051 4.56464 3.20875 4.6117C3.39698 4.63523 2.8558 4.98817 2.83228 4.56464Z"
    fill="#FF9500"
  />
  <g style="mix-blend-mode: multiply" opacity="0.8">
    <path
      d="M13.0441 2.49417C13.0284 2.4628 13.0128 2.43927 12.9971 2.42358C13.0128 2.45496 13.0284 2.47848 13.0441 2.49417Z"
      fill="#D42F55"
    />
  </g>
  <g style="mix-blend-mode: multiply" opacity="0.8">
    <path
      d="M12.9971 2.42353C12.8559 2.25882 12.503 1.88235 11.8912 1.48235C11.1618 0.988232 10.0795 0.494115 8.62063 0.305879C8.58926 0.290193 8.56573 0.282349 8.55005 0.282349C8.33044 0.266662 8.10298 0.25098 7.86769 0.235294C7.852 0.235294 7.82848 0.235294 7.7971 0.235294C7.76573 0.235294 7.7422 0.235294 7.72652 0.235294C7.35005 0.219608 6.96573 0.227445 6.57357 0.258818C6.47945 0.258818 6.38534 0.266662 6.29122 0.282349C4.1971 0.517643 2.78534 1.41176 1.91475 2.28235C1.20887 2.98823 0.855928 3.64705 0.738281 3.90588C1.46769 2.21176 2.80887 0.870585 4.47946 0.305879C4.47946 0.305879 4.4873 0.305879 4.50299 0.305879C4.5971 0.274507 4.69906 0.243136 4.80887 0.211763C4.84024 0.196077 4.87161 0.188232 4.90299 0.188232C4.91867 0.188232 4.93436 0.180388 4.95004 0.164702C5.05985 0.149015 5.16181 0.133333 5.25593 0.117647C5.30299 0.101961 5.35789 0.0941162 5.42063 0.0941162C5.70299 0.0470574 5.99318 0.0156863 6.29122 0C6.58926 0.0156863 6.87161 0.0392128 7.13828 0.0705854L8.62063 0.188232C10.3618 0.235291 11.9148 1.08235 12.9971 2.42353Z"
      fill="#D42F55"
    />
  </g>
  <g style="mix-blend-mode: multiply" opacity="0.8">
    <path
      d="M12.5028 12.047C12.3459 12.2195 12.1812 12.3764 12.0087 12.5176C11.993 12.5176 11.9851 12.5254 11.9851 12.5411C11.7263 12.7529 11.444 12.9411 11.1851 13.0823C11.1851 13.0823 11.1773 13.0901 11.1616 13.1058C10.3616 13.5529 9.44397 13.8352 8.50279 13.8587H8.47926L7.2322 13.9293C6.96553 13.9764 6.69102 13.9999 6.40867 13.9999C6.36161 13.9999 6.3224 13.9999 6.29102 13.9999H6.2675C5.70279 13.9764 5.16161 13.8823 4.66749 13.7176C4.58906 13.7019 4.51847 13.6783 4.45573 13.647C4.44004 13.647 4.42436 13.6391 4.40867 13.6234C3.44396 13.294 2.90279 12.7999 2.78514 12.7058C2.39299 12.3921 2.04004 12.0391 1.72632 11.647C1.77338 11.694 2.94985 12.847 4.66749 13.4823C5.16161 13.6705 5.70279 13.8117 6.2675 13.8587C6.65965 13.9215 7.06749 13.9372 7.49102 13.9058C7.53808 13.8901 7.5773 13.8823 7.60867 13.8823C7.71848 13.8666 7.82828 13.8587 7.93808 13.8587C7.95377 13.843 7.96945 13.8352 7.98514 13.8352C8.25181 13.8038 8.50279 13.7646 8.73808 13.7176C10.7381 13.3176 11.9851 12.447 12.3851 12.1411C12.4479 12.094 12.4871 12.0627 12.5028 12.047Z"
      fill="#D42F55"
    />
  </g>
  <path
    d="M9.67928 0.517703C8.90281 0.470644 10.5263 1.67064 11.1381 2.16476C11.7734 2.68241 12.8087 2.32947 12.8558 2.28241C12.8793 2.21182 11.2558 0.564762 9.67928 0.517703Z"
    fill="white"
  />
  <defs>
    <linearGradient
      id="paint0_linear_4368_11633"
      x1="0.763419"
      y1="13.8635"
      x2="0.763419"
      y2="0.186821"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.05" stop-color="#FF3F00" />
      <stop offset="0.718" stop-color="#FF6A03" />
      <stop offset="1" stop-color="#FFFF04" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_4368_11633"
      x1="-32.1049"
      y1="13.9998"
      x2="-32.1049"
      y2="0.0115293"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.05" stop-color="#FF3F00" />
      <stop offset="0.718" stop-color="#FF6A03" />
      <stop offset="1" stop-color="#FFFF04" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_4368_11633"
      x1="-1.47703"
      y1="13.9998"
      x2="-1.47703"
      y2="0.0115293"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FFA900" />
      <stop offset="1" stop-color="#FF9600" />
    </linearGradient>
    <linearGradient
      id="paint3_linear_4368_11633"
      x1="1.10396"
      y1="11.8512"
      x2="1.10396"
      y2="2.66999"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FF7C04" />
      <stop offset="1" stop-color="#D92000" />
    </linearGradient>
    <linearGradient
      id="paint4_linear_4368_11633"
      x1="3.63634"
      y1="13.0505"
      x2="0.476011"
      y2="4.36792"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#EA5104" />
      <stop offset="1" stop-color="#D92000" />
    </linearGradient>
    <linearGradient
      id="paint5_linear_4368_11633"
      x1="3.18098"
      y1="13.032"
      x2="0.0316583"
      y2="4.37927"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FF9600" />
      <stop offset="1" stop-color="#FFA900" />
    </linearGradient>
    <linearGradient
      id="paint6_linear_4368_11633"
      x1="11.0934"
      y1="10.8636"
      x2="11.0934"
      y2="9.23089"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.042" stop-color="#EF6300" />
      <stop offset="1" stop-color="#E13E00" />
    </linearGradient>
    <linearGradient
      id="paint7_linear_4368_11633"
      x1="10.1944"
      y1="10.481"
      x2="10.1944"
      y2="9.45276"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.042" stop-color="#EF6300" />
      <stop offset="1" stop-color="#E13E00" />
    </linearGradient>
    <linearGradient
      id="paint8_linear_4368_11633"
      x1="2.72096"
      y1="4.76491"
      x2="2.72991"
      y2="3.90209"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.042" stop-color="#EF6300" />
      <stop offset="1" stop-color="#E13E00" />
    </linearGradient>
  </defs>
</svg>
