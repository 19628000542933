<div class="title">Notification</div>

<div class="list">
  <app-notification-item
    *ngFor="let item of notificationService.notifications$ | async"
    [data]="item"></app-notification-item>

  <app-empty
    *ngIf="(notificationService.notifications$ | async) && (notificationService.notifications$ | async)!.length <= 0"
    message="It looks like there are no existing notifications"></app-empty>
</div>
