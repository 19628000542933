import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingMenuComponent } from './floating-menu.component';
import { NotificationListComponent } from 'src/app/shared/components/notification/notification-list/notification-list.component';
import { FloatingMenuService } from './floating-menu.service';
import { ReferralsSectionComponent } from 'src/app/shared/components/referrals/referrals-section/referrals-section.component';
import { LeaderboardSectionComponent } from 'src/app/shared/components/leaderboard/leaderboard-section/leaderboard-section.component';

@NgModule({
  declarations: [FloatingMenuComponent],
  exports: [FloatingMenuComponent],
  imports: [CommonModule, NotificationListComponent, ReferralsSectionComponent, LeaderboardSectionComponent],
  providers: [FloatingMenuService]
})
export class FloatingMenuModule {}
