import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDropdownMenuItem } from '../main-menu-items';
import { MainMenuService } from '../main-menu.service';
import { FloatingMenuService } from '../../floating-menu/floating-menu.service';

@Component({
  selector: 'menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuDropdownComponent {
  @Input() items!: IDropdownMenuItem[];

  constructor(
    private mainMenuService: MainMenuService,
    private floatingMenuService: FloatingMenuService
  ) {}

  close() {
    this.mainMenuService.closeDropdown();
  }

  openFloatingMenu(item: IDropdownMenuItem) {
    if (!item.isFloatingMenuItem) {
      return;
    }

    this.floatingMenuService.openDrawer(item.id);
    this.close();
  }
}
