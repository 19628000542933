<section class="leaderboard__section">
  <h3 class="leaderboard__section__title">How to earn Gladiator points</h3>

  <div class="leaderboard__section__content">
    <a
      *ngFor="let item of items"
      class="leaderboard__section__content__item"
      [ngClass]="{ disabled: item?.disabled }"
      [routerLink]="(isMobile$ | async) ? item?.link : undefined"
      (click)="handleClick(item.cb)"
    >
      <span [ngClass]="item.icon" class="icon"></span>

      <p>
        {{ item.title }}
      </p>

      <span class="leaderboard__section__content__item__open">{{ item?.disabled ? 'Coming soon' : 'Open' }}</span>
    </a>
  </div>
</section>
