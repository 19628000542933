import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MeldService } from '../../../services/meld.service';
import { environment } from '../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { SafeUrlPipe } from 'src/app/shared/pipes/safeUrl.pipe';

@Component({
  selector: 'meld-modal',
  templateUrl: './meld-modal.component.html',
  styleUrls: ['./meld-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, SafeUrlPipe, MatDialogModule],
  providers: [MeldService]
})
export class MeldModalComponent implements OnInit {
  urlSafe: string = '';
  destinationCurrencyCode = encodeURIComponent('GMRX_BSC');
  sourceCurrencyCode = encodeURIComponent('USD');
  transactionType = encodeURIComponent('BUY');
  countryCode = encodeURIComponent('US');

  constructor(
    private dialogRef: MatDialogRef<MeldModalComponent>,
    private meldService: MeldService
  ) {}

  closeModal() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.meldService.getCryptoWizardDetailsRequest().subscribe({
      next: (response) => {
        if (response.success) {
          const { publicKey, walletAddress, userId } = response.data!;

          const urlParams = new URLSearchParams({
            publicKey: publicKey,
            countryCode: this.countryCode,
            sourceCurrencyCode: this.sourceCurrencyCode,
            destinationCurrencyCode: this.destinationCurrencyCode,
            walletAddress: walletAddress ?? '',
            externalCustomerId: userId.toString(),
            transactionType: this.transactionType
          });

          this.urlSafe = `${environment.meldLink}/?${urlParams.toString()}`;
        }
      },
      error: () => {
        this.closeModal();
      }
    });
  }
}
