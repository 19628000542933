<div class="modal" *ngIf="showSlider">
  <div class="modal-content">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let item of [].constructor(data.infographics.count); let i = index">
        <ng-template carouselSlide [id]="i.toString()">
          <img [src]="data.infographics.folder + (i + 1) + data.fileExtension || '.png'" alt="Infographics" lazyLoad />
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
