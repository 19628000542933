<ul>
  <li *ngFor="let item of items">
    <button class="item" *ngIf="item.isFloatingMenuItem" (click)="openFloatingMenu(item)">
      <ng-container *ngTemplateOutlet="itemContent; context: { $implicit: item }"></ng-container>
    </button>
    <a
      class="item"
      *ngIf="item.path && !item.isFloatingMenuItem"
      [routerLink]="item.path"
      routerLinkActive="active"
      (click)="close()"
      ><ng-container *ngTemplateOutlet="itemContent; context: { $implicit: item }"></ng-container
    ></a>
  </li>
</ul>

<ng-template #itemContent let-item>
  <span class="icon {{ item.icon }}"></span>
  <span class="text">{{ item.title }}</span>
  <span *ngIf="item.iconRight" class="icon {{ item.iconRight }}"></span>
</ng-template>
