<header class="home-header" *ngIf="loaded" @fadeInOut>
  <app-email-confirmation></app-email-confirmation>
  <div class="home-wide-container">
    <div class="home-header__menu-mobile" (click)="openSidebar()">
      <span class="icon-Menu"></span>
    </div>
    <ul class="home-header__menu">
      <li (click)="goToPackages()">Packages</li>
      <li (click)="goToFaqs()">FAQ</li>
      <li (click)="goToBlog()">Blog</li>
    </ul>
    <img
      class="home-header__logo"
      src="../../../../../assets/gladiators-logo.svg"
      alt="Gaimin Gladiators"
      (click)="openRoot()"
      lazyLoad />
    <div *ngIf="(isLoggedIn$ | async) === false" class="home-header__btns">
      <app-button (click)="signIn()" [buttonData]="signInBtn"></app-button>
      <app-button (click)="becomeMember()" [buttonData]="memberBtn"></app-button>
    </div>
    <app-header-profile
      *ngIf="isLoggedIn$| async"
      class="header__profile"
      [isHomePage]="true"></app-header-profile>
  </div>
</header>

<div class="home-sidebar__wrapper"  *ngIf="loaded" @fadeInOut [ngClass]="{ active: isSideBarOpen$ | async }" (click)="closeSidebar()">
  <div class="home-sidebar" [ngClass]="{ active: isSideBarOpen$ | async }" (click)="stopPropagation($event)">
    <span class="close-btn icon-Close-Bold" (click)="closeSidebar()"></span>
    <div class="home-sidebar__header">
      <img src="../../../../assets/gladiators-logo.svg" alt="Gaimin Gladiators" (click)="openRoot()" lazyLoad />
    </div>
    <ul class="home-sidebar__menu">
      <li (click)="goToPackages()">Packages <span class="icon-Arrow-Right"></span></li>
      <li (click)="goToFaqs()">FAQ <span class="icon-Arrow-Right"></span></li>
      <li (click)="goToBlog()">Blog <span class="icon-Arrow-Right"></span></li>
    </ul>
    <div class="home-sidebar__btns" *ngIf="(isLoggedIn$ | async) === false">
      <app-button (click)="signIn()" [buttonData]="signInBtn"></app-button>
      <app-button (click)="becomeMember()" [buttonData]="memberBtn"></app-button>
    </div>
  </div>
</div>
