import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReferralsListResponse, Response } from '../../intarfaces';
import { BehaviorSubject, filter, switchMap, take, tap } from 'rxjs';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ReferralsService {
  private referralLinkSrc = new BehaviorSubject<string | null>(null);
  public referralLink$ = this.referralLinkSrc.asObservable();

  private referralsListSrc = new BehaviorSubject<ReferralsListResponse | null>(null);
  public referralsList$ = this.referralsListSrc.asObservable();

  private userBalanceSrc = new BehaviorSubject<number | null>(null);
  public userBalance$ = this.userBalanceSrc.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private toastService: ToastrService
  ) {}

  public loadReferralLink(): void {
    if (this.referralLinkSrc.getValue()) {
      return;
    }
    this.getReferralLinkRequest().subscribe((response) => {
      if (response.success && response.data) {
        this.referralLinkSrc.next(response.data);
      }
    });
  }

  public loadReferralsList(page: number, size: number): void {
    this.getReferralsListRequest(page, size).subscribe((response) => {
      if (response.success && response.data) {
        this.referralsListSrc.next(response.data);
      }
    });
  }

  public loadUserBalance(): void {
    this.getUserBalanceRequest().subscribe((response) => {
      if (response.success && response.data) {
        this.userBalanceSrc.next(response.data);
      }
    });
  }

  public sendInvite(email: string) {
    return this.http.post<Response<void>>(`${environment.gaiminApi}/referral/invite?email=${email}`, null).pipe(
      tap((response) => {
        if (response.success) {
          this.toastService.success('Invite sent successfully');
        } else {
          this.toastService.error('Invite sending failed');
        }
      })
    );
  }

  private getReferralsListRequest(page: number, size: number) {
    return this.userService.userInfo$.pipe(
      filter((userInfo) => !!userInfo.id),
      take(1),
      switchMap((userInfo) => {
        return this.http.get<Response<ReferralsListResponse>>(
          `${environment.gaiminApi}/referral/user-referrals/${userInfo.id}?page=${page}&size=${size}`
        );
      })
    );
  }

  private getReferralLinkRequest() {
    return this.http.get<Response<string>>(`${environment.gaiminApi}/referral/link`);
  }

  private getUserBalanceRequest() {
    return this.userService.userInfo$.pipe(
      filter((userInfo) => !!userInfo.id),
      take(1),
      switchMap((userInfo) => {
        return this.http.get<Response<number>>(`${environment.gaiminApi}/referral/user-balance/${userInfo.id}`);
      })
    );
  }
}
