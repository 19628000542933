import { Component, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { InfographicsSlider } from '../../../../shared/interfaces';
import { CommonModule } from '@angular/common';
import { LazyLoadDirective } from 'src/app/shared/directives/lazy-load.directive';

@Component({
  selector: 'infographicst-modal',
  templateUrl: './infographics-modal.component.html',
  styleUrls: ['./infographics-modal.component.scss'],
  imports: [CommonModule, CarouselModule, LazyLoadDirective],
  standalone: true
})
export class InfographicsModalComponent implements AfterViewInit, OnDestroy {
  customOptions: OwlOptions = {
    items: 1,
    nav: true,
    autoWidth: true,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 0,
    navSpeed: 500,
    navText: ['<span class="icon-Arrow-Left"></span>', '<span class="icon-Arrow-Right"></span>'],
    startPosition: this.data.index
  };

  private timeOut!: ReturnType<typeof setTimeout>;
  showSlider: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<InfographicsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { infographics: InfographicsSlider; index: number; fileExtension: `.${string}` }
  ) {}

  close() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.timeOut = setTimeout(() => {
      this.showSlider = true;
    }, 100);
  }

  ngOnDestroy() {
    clearTimeout(this.timeOut);
  }
}
