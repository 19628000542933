import { Component } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-faqs-queries',
  templateUrl: './faqs-queries.component.html',
  styleUrls: ['./faqs-queries.component.scss'],
  imports: [MatExpansionModule],
  standalone: true
})
export class FaqsQueriesComponent {
  scrollToExpanded(id: string) {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }
}
