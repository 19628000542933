<div class="payment-modal">
  <div class="header">
    <h2>Subscription management</h2>
    <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
  </div>

  <div class="steps">
    <div class="steps__wrapper">
      <div class="steps__container steps__container-desktop">
        <div class="steps__step" *ngFor="let step of steps; index as i">
          <div class="steps__step__header">
            <span class="steps__step__number">
              <span>{{ i + 1 }}</span
              >/{{ steps.length }}</span
            >
            <span class="steps__step__title">{{ step.title }}</span>
          </div>
        </div>
      </div>

      <div class="steps__container steps__container-mobile">
        <div class="steps__step">
          <div class="steps__step__header">
            <span class="steps__step__number">
              <span>{{ currentStepPosition + 1 }}</span
              >/{{ steps.length }}</span
            >
            <span class="steps__step__title">{{ steps[currentStepPosition].title }}</span>
          </div>

          <span class="steps__step__next" *ngIf="!!steps[currentStepPosition + 1]?.title?.length">{{
            steps[currentStepPosition + 1].title
          }}</span>
        </div>
      </div>

      <div class="steps__line__container">
        <div
          *ngFor="let step of steps; index as i"
          class="steps__line"
          [ngClass]="{
            active: i <= currentStepPosition,
            danger: isMobile
              ? i <= currentStepPosition && (subsService.errorMessage$ | async)
              : isActiveStep(step.step) && (subsService.errorMessage$ | async),
            loading:
              isActiveStep(step.step) &&
              ((subsService.currentPaymentStep$ | async) === PAYMENT_STEPS.CONFIRMAITON_SPEND ||
                (subsService.currentPaymentStep$ | async) === PAYMENT_STEPS.CONFIRMAITON_SWAP ||
                (subsService.currentPaymentStep$ | async) === PAYMENT_STEPS.SWAP_TOKEN)
          }"
          [ngStyle]="{ borderRadius: isMobile ? (i === currentStepPosition ? '0 6px 6px 0' : '0') : '6px' }"></div>
      </div>
    </div>

    <div class="steps__content">
      <ng-container [ngSwitch]="subsService.currentPaymentStep$ | async">
        <div class="steps__content-item" *ngSwitchCase="PAYMENT_STEPS.ALLOW_GMRX_SPEND">
          <span class="steps__content__title">Allow GMRX spend</span>
          <span
            class="steps__content__text"
            *ngIf="
              (subsService.errorMessage$ | async) === null || (subsService.errorMessage$ | async) === '';
              else error
            "
            >Please confirm the proposed GMRX allowance in your wallet</span
          >

          <ng-template #error>
            <span class="steps__content__text">{{ subsService.errorMessage$ | async }}</span>
          </ng-template>
        </div>

        <div class="steps__content-item" *ngSwitchCase="PAYMENT_STEPS.CONFIRMAITON_SPEND">
          <span class="loader"></span>
          <span class="steps__content__title">Receive allowance confirmation</span>
          <span class="steps__content__text">Please wait until allowance transaction is confirmed</span>

          <div class="transaction">
            <span>View your transaction here</span>
            <div class="transaction__divider"></div>
            <a
              href="{{ transactionUrl }}{{ subsService.approvalHash$ | async }}"
              target="_blank"
              rel="noopener noreferrer"
              >{{ subsService.approvalHash$ | async }}
            </a>
          </div>
        </div>

        <div class="steps__content-item" *ngSwitchCase="PAYMENT_STEPS.SPEND_CONFIRMED">
          <span class="steps__content__title">Receive allowance confirmation</span>
          <span class="steps__content__text">Allowance transaction is confirmed</span>

          <div class="transaction">
            <span>View your transaction here</span>
            <div class="transaction__divider"></div>
            <a
              href="{{ transactionUrl }}{{ subsService.approvalHash$ | async }}"
              target="_blank"
              rel="noopener noreferrer"
              >{{ subsService.approvalHash$ | async }}
            </a>
          </div>
        </div>

        <div class="steps__content-item" *ngSwitchCase="PAYMENT_STEPS.SWAP_TOKEN">
          <span class="steps__content__title">Subscribe</span>
          <span
            class="steps__content__text"
            *ngIf="
              (subsService.errorMessage$ | async) === null || (subsService.errorMessage$ | async) === '';
              else error
            "
            >Please confirm Subscribe transaction in your wallet</span
          >

          <ng-template #error>
            <span class="steps__content__text">{{ subsService.errorMessage$ | async }}</span>
          </ng-template>
        </div>

        <div class="steps__content-item" *ngSwitchCase="PAYMENT_STEPS.CONFIRMAITON_SWAP">
          <span class="loader"></span>
          <span class="steps__content__title">Subscribe</span>
          <span class="steps__content__text">Please wait until Subscribe transaction is confirmed</span>

          <div class="transaction" *ngIf="subsService.swapTokenHash$ | async">
            <span>View your transaction here</span>
            <div class="transaction__divider"></div>
            <a
              href="{{ transactionUrl }}{{ subsService.swapTokenHash$ | async }}"
              target="_blank"
              rel="noopener noreferrer"
              >{{ subsService.swapTokenHash$ | async }}
            </a>
          </div>
        </div>

        <div class="steps__content-item" *ngSwitchCase="PAYMENT_STEPS.SWAP_TOKEN_CONFIRMED">
          <span class="steps__content__title">Subscribe</span>
          <span class="steps__content__text">Subscribe transaction is confirmed</span>

          <div class="transaction" *ngIf="subsService.swapTokenHash$ | async">
            <span>View your transaction here</span>
            <div class="transaction__divider"></div>
            <a
              href="{{ transactionUrl }}{{ subsService.swapTokenHash$ | async }}"
              target="_blank"
              rel="noopener noreferrer"
              >{{ subsService.swapTokenHash$ | async }}
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
