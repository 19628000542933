import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class LoaderComponent implements OnInit {
  isLoading: Boolean = false;

  constructor(public loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe((state) => (this.isLoading = state));
  }
}
