<div class="ticker">
  <div class="ticker-container">
    <div class="ticker-container-items" #tickerContainer>
      <div class="ticker-container-items-repeat" *ngFor="let counter of repeatCounter">
        <img *ngFor="let image of tickerData" [src]="imgPath + image" alt="Partners" lazyLoad />
      </div>
    </div>
  </div>
  <div #textWidthElement id="ghost">
    <div class="ghost-container">
      <img *ngFor="let image of tickerData" [src]="imgPath + image" alt="Partners" lazyLoad />
    </div>
  </div>
</div>
