import { CommonModule } from '@angular/common';
import { Component, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'email-confirmation-modal',
  templateUrl: './email-confirmation-modal.component.html',
  styleUrls: ['./email-confirmation-modal.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class EmailConfirmationModalComponent {
  @Output() resendRequest: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dialogRef: MatDialogRef<EmailConfirmationModalComponent>) {}

  resendEmailVerification() {
    this.dialogRef.close();
    this.resendRequest.emit();
  }

  close() {
    this.dialogRef.close();
  }
}
