<h3>Infographics</h3>
<owl-carousel-o [options]="customOptions">
  <ng-container *ngFor="let item of [].constructor(infographics.count); let i = index">
    <ng-template carouselSlide [id]="i.toString()">
      <img
        [src]="infographics.folder + (i + 1) + fileExtension"
        alt="Infographics"
        (click)="openPopup(i)"
        lazyLoad />
    </ng-template>
  </ng-container>
</owl-carousel-o>
