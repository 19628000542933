import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { IDropdownMenuItem } from '../main-menu-items';
import { map } from 'rxjs';
import { FAN_AREA_ITEMS } from 'src/app/shared/enums';

@Component({
  selector: 'menu-bottom-sheet',
  templateUrl: './menu-bottom-sheet.component.html',
  styleUrls: ['./menu-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuBottomSheetComponent {
  public items$ = this.notificationService.hasNewNotifications$.pipe(
    map((hasNewNotifications) => {
      return this.data.items.map((item) => {
        if (item.id === FAN_AREA_ITEMS.NOTIFICATION) {
          return {
            ...item,
            badge: hasNewNotifications
          };
        }

        return item;
      });
    })
  );

  constructor(
    private notificationService: NotificationService,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: { items: IDropdownMenuItem[] }
  ) {}
}
