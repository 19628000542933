<div class="container">
  <div class="rewards">
    <h1 class="title">Rewards</h1>
    <div class="rewards-list">
      <div class="rewards-list__item" *ngFor="let item of rewards">
        <div class="rewards-list__item-img">
          <img
            [src]="imagePath"
            [alt]="item.name"
            [ngClass]="{ 'coming-soon': item.comingSoon }"
            (load)="onImageLoad()"
            lazyLoad />
          <span *ngIf="item.comingSoon" class="rewards-list__item-coming-soon">Coming soon</span>
        </div>
        <p class="rewards-list__item-name">{{ item.name }}</p>
      </div>
    </div>
  </div>
</div>
