<div class="password-field" *ngIf="{isPasswordVisible: isPasswordVisible$ | async} as ctx">
  <input
    [value]="value"
    (input)="handleInputChange($event)"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [type]="ctx.isPasswordVisible ? 'text' : 'password'"
    class="change-password__field"
    [id]="id">
  <span
    (click)="togglePasswordVisibility()"
    class="icon"
    [ngClass]="{
      'icon-Closed-Eye': !ctx.isPasswordVisible,
      'icon-Eye': ctx.isPasswordVisible
    }"></span>
</div>
