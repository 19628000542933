<div class="header">
  <img
    *ngIf="!isHomePage"
    class="header__logo"
    src="../../../../assets/gladiators-logo.svg"
    alt="Gaimin Gladiators"
    (click)="openRoot()"
    lazyLoad />
  <ng-container *ngIf="isLoggedIn$ | async">
    <div
      class="header__item subscription"
      [ngClass]="{ 'hide-xs': isHomePage }"
      *ngIf="userSubscriptionData.type && userSubscriptionData.type !== COLOSSEUM_TIERS.BRONZE">
      <span class="icon"
        ><img class="tier" [src]="userSubscriptionData.img" [alt]="userSubscriptionData.name" lazyLoad
      /></span>
      <span class="text"> {{ userSubscriptionData.name }} </span>
      <span class="days">{{ userSubscriptionData.days }}</span>
    </div>

    <div
      class="header__item hide-mobile"
      *ngIf="(walletConnectService.walletGmrxBalance$ | async) === null"
      (click)="connectWallet()">
      <span class="icon icon-Withdraw"> </span>
      <span class="text"> Connect wallet </span>
    </div>

    <div
      class="header__item hide-mobile"
      *ngIf="(walletConnectService.walletGmrxBalance$ | async) !== null"
      (click)="connectWallet()">
      <span class="icon">
        <img src="assets/icons/coins/gmrx.png" alt="GMRX" lazyLoad />
      </span>
      <span class="text">
        {{ walletConnectService.walletGmrxBalance$ | async }}
        <ng-container *ngIf="(walletConnectService.walletGmrxUSDBalance$ | async) !== null">
          (${{ walletConnectService.walletGmrxUSDBalance$ | async | number: '1.2-2' }})
        </ng-container>
      </span>
    </div>

    <div
      class="header__item hide-mobile"
      *ngIf="(walletConnectService.walletBnbBalance$ | async) !== null"
      (click)="connectWallet()">
      <span class="icon">
        <img src="assets/icons/coins/bnb.svg" alt="BNB" lazyLoad />
      </span>
      <span class="text">
        {{ walletConnectService.walletBnbBalance$ | async | number: '1.5-5' }}
        <ng-container *ngIf="(walletConnectService.walletBnbUSDBalance$ | async) !== null">
          (${{ walletConnectService.walletBnbUSDBalance$ | async | number: '1.2-2' }})
        </ng-container>
      </span>
    </div>

    <div
      class="header__icon-btn hide-desktop"
      [ngClass]="{ 'hide-mobile': isHomePage }"
      *ngIf="(walletConnectService.walletGmrxBalance$ | async) !== null"
      (click)="buyGMRX(EXCHANGE_CURRENCY.GMRX)">
      <img src="assets/icons/coins/gmrx.png" alt="GMRX" lazyLoad />
    </div>

    <div
      class="header__icon-btn hide-desktop"
      [ngClass]="{ 'hide-mobile': isHomePage }"
      *ngIf="(walletConnectService.walletBnbBalance$ | async) !== null"
      (click)="buyGMRX(EXCHANGE_CURRENCY.BNB)">
      <img src="assets/icons/coins/bnb.svg" alt="BNB" lazyLoad />
    </div>

    <app-button
      class="header__btn"
      *ngIf="userSubscriptionData.type === COLOSSEUM_TIERS.BRONZE"
      (click)="becomeMember()"
      [buttonData]="becomeMemberBtn"></app-button>
    <app-button
      *ngIf="!isHomePage"
      class="header__btn hide-mobile"
      (click)="buyGMRX()"
      [buttonData]="buyGMRXBtnDesktop"></app-button>
    <app-button
      *ngIf="!isHomePage && (walletConnectService.isWalletConnected$ | async) === false"
      class="header__btn hide-desktop"
      (click)="connectWallet(true)"
      [buttonData]="buyGMRXBtnMobile"></app-button>
    <app-button
      *ngIf="isHomePage && userService.isAccountActive()"
      class="header__btn"
      (click)="goToColosseum()"
      [buttonData]="goToColosseumBtn"></app-button>

    <div *ngIf="userService.userInfo$ | async" class="header__profile" clickOutside (clickOutside)="closeDropDown()">
      <img
        [src]="(userService.userInfo$ | async)?.avatarUrl"
        (click)="openDropDown()"
        onError="this.src = 'assets/icons/default-user-image.png'"
        alt="Avatar"
        height="40"
        width="40"
        lazyLoad />
      <div *ngIf="isDropDownOpen" class="drop-down">
        <ul>
          <li (click)="openProfile()">My account</li>
          <li (click)="buyGMRX()">Buy GMRX</li>
          <li (click)="closeDropDown(); connectWallet()">
            {{ (walletConnectService.isWalletConnected$ | async) ? 'My wallet' : 'Connect wallet' }}
          </li>
          <li (click)="logout()">Sign out</li>
        </ul>
      </div>
    </div>
  </ng-container>

  <div *ngIf="(isLoggedIn$ | async) === false" class="header__btns">
    <app-button (click)="signIn()" [buttonData]="signInBtn"></app-button>
  </div>
</div>
